export const onStretchBlock = (e:any, parent='.table-pagination')=> {
    e = e || window.event;
    const el = (e.srcElement || e.target).parentNode.parentNode;
    const block = el.closest(parent);
    const stretches = block.querySelectorAll(`th${parent}__stretch-cell`);
    const titles = block.querySelectorAll(`th${parent}__th`);
    const index = Array.from(titles).indexOf(el);
    const getIndexByWeight = (arr: Array<any>, weight: number) => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
            sum += arr[i].colSpan;
            if (sum >= weight) {
                return i;
            }
        }
        return arr.length - 1;
    }
    let stretch;
    let indexStretch;
    if (stretches.length > 0) {
        indexStretch = getIndexByWeight(stretches, index + 1);
        stretch = stretches[indexStretch];
    }
    const elements = block.querySelectorAll(`td:nth-child(${index + 1})`);
    const headBlock = block.querySelector(`${parent}__head-block`);
    const bodyBlock = block.querySelector(`${parent}__scroll`);
    const totalBlock = block.querySelector(`${parent}__total`);
    const result: any = {
        'index': index,
        'el': el,
        'x': e.clientX - el.offsetWidth,
        'y': e.clientY - el.offsetHeight,
        'elements': elements,
        'head': headBlock,
        'body': bodyBlock,
        'total': totalBlock
    };
    if (stretch && typeof indexStretch === 'number') {
        const elementsFirstRow = block.querySelectorAll(`tr:first-child > td`);
        result['stretch'] = stretch;
        const elems = [];
        for (let i = indexStretch; i < stretch.colSpan; i++) {
            elems.push(elementsFirstRow[i])
        }
        result['stretch_indexes'] = elems;
    }
    return result;
}