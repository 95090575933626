import {useRef} from "react";
import {useOnClickOutside} from "../../../../../helpers";
import Btn from "../../../../../ui/Btn";
import {trans} from "../../../../../_locales";

const StringSortByValue = ({
        activeFlag,
        setActiveFlag,
        language='ru',
        onReset,
        onSubmit
    }: {
        activeFlag:boolean,
        setActiveFlag:any,
        language?:string,
        onReset:any,
        onSubmit:any,
    }) => {
        const selectRef = useRef<HTMLDivElement>(null);
        useOnClickOutside(() => {
            setActiveFlag(false)
        }, [selectRef]);

        const onSubmitSort = ()=> {
            onSubmit();
        }
        const onResetSort = ()=> {
            onReset();
        }

        return(
            <div className={`number-sort-modal ${activeFlag ? 'active' : ''}`} ref={selectRef}>
                <div className="number-sort-modal__btn">
                    <Btn
                        text={trans("Doesn't contain empty", language)}
                        color={'blue'}
                        onClick={onSubmitSort}
                        isfullWidth={true}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        text={trans('Reset', language)}
                        color={'light-blue'}
                        onClick={onResetSort}
                        isfullWidth={true}
                    />
                </div>
            </div>
        )
    }

export default StringSortByValue;