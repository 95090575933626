import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    DndContext,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
    closestCorners,
    TouchSensor
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import {
    restrictToHorizontalAxis
} from '@dnd-kit/modifiers';

import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../redux/slices/user";
import {setBreadcrumbs} from "../../redux/slices/breadcrumbs";
import {setSidebar} from "../../redux/slices/sidebar";

import iconDocuments from '../../assets/icons/documents.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg';
import CloseIcon from '../../assets/icons/icon-close-red.svg'
import whiteClose from '../../assets/icons/icon-close-white.svg'

import {trans} from "../../_locales";
import Select from "../../components/ui/Select";
import Input from "../../ui/Input";
import Btn from "../../ui/Btn";
import {
    Groups,
    Models,
    Periods,
    PopupType,
    REG_EMAIL,
    TIMES,
    Windows
} from "../../data";
import {getCustomDashboards, getProjectAttributions, getProjectGeneral} from "../../actions/project";
import {getEvents} from "../../actions/events";
import {eventsData} from "../../redux/slices/events";

import {
    calculateMonthsBetweenDates,
    filterObject,
    formatDate,
    getFromObject,
    getInterval,
    isValidDateFormat, onRemoveFormItem, parseCustomFilter, parseCustomFilterItem,
    parseFilter,
    parseSort,
    removeObjectKeys,
    sendSdk
} from "../../utils";
import TablePagination from "../../components/Tables/TablePagination";
import {reportsData} from "../../redux/slices/reports";
import {createReport, getReports, updateReport} from "../../actions/reports";
import {ICustomReport} from "./interfaces.d";
import {
    fields,
    fieldsConditions,
    fieldsConditionValue,
    fieldsConditionValueTypes,
    metrics,
    metricsConditions,
    metricsConditionValue,
    metricsConditionValueTypes,
    fieldsSite,
    fieldsConditionsSite,
    fieldsConditionValueSite,
    fieldsConditionValueTypesSite,
    metricsSite,
    metricsConditionsSite,
    metricsConditionValueSite,
    metricsConditionValueTypesSite,
    fieldsEcom,
    fieldsConditionsEcom,
    fieldsConditionValueEcom,
    fieldsConditionValueTypesEcom,
    metricsEcom,
    metricsConditionsEcom,
    metricsConditionValueEcom,
    metricsConditionValueTypesEcom,
    fieldsEcomItems
} from "../../pages/Projects/Project/Reports/data";
import './style.scss';
import FilterItem from "../Modals/FilterDashboards/FilterItem";
import {Loader} from "../index";
import {onChangeGeneralCheckbox, onChangeGeneralSelect} from "../ui/Select/functions";
import FieldItem from "./FieldItem";
import {openPopup} from "../../redux/slices/popup";
import {getDateAgo} from "../ui/TimeFilter/functions";
import SortItem from "../Modals/FilterDashboards/SortItem";
import {clearInstructions, setInstructions} from "../../redux/slices/instructions";
import {ModalName, openModal} from "../../redux/slices/modals";
import {DateRange, DayPicker} from "react-day-picker";
import ru from "date-fns/locale/ru";
import {useCallbackPrompt} from "../../hooks/useCallbackPrompt";
import Toggle from "../../ui/Toggle";
import {useOnClickOutside} from "../../helpers";
import {DefaultObject} from "../../interfaces/common.d";
import CustomFilterItem from "../../pages/Projects/Project/Reports/components/CustomFilterItem";
import EcomFilterItem from "../../pages/Projects/Project/Reports/components/EcomFilterItem";
import {datasetConditions, datasetName, datasetValue} from "../Modals/FilterDashboards/data";
import {getPages} from "../SideMenu/Role";

const ReportUpdate = ({
    reportType='create',
    projectId,
    reportId
}:{reportType:'edit'|'based'|'create', projectId:any, reportId?:any}) => {
    const reduxUser = useAppSelector(currentUser);
    const reduxReports = useAppSelector(reportsData);
    const language = useAppSelector(currentUserLang);
    const reduxEvents = useAppSelector(eventsData);
    const reduxUserId = useAppSelector(userId);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const [dashboard, setDashboard] = useState<string>('custom_table_main');
    // const dashboard = 'custom_table_full_stat';
    // const dashboard = 'custom_table_domain';
    const [firstLoading, setFirstLoading] = useState(true);

    const [exitCounter, setExitCounter] = useState<boolean>(false)
    const ref = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        dispatch(setBreadcrumbs([
            {
                href: '/projects/',
                title: 'Проект',
            },
            {
                href: `/projects/${projectId}/custom-reports/`,
                title: trans('Custom reports', language)
            },
            {
                href: `/projects/${projectId}/custom-reports/create/`,
                title: reportType === 'edit' ?
                    trans('Edit report', language) :
                    trans('New report', language)
            }
        ]))
        dispatch(setSidebar(getPages({
            projectId: Number(projectId),
            access: permissions && permissions['can_view'],
            language,
            active: 'upload'
        })))
        dispatch(getProjectGeneral({currentUserId: reduxUser.id, projectId: Number(projectId)}));
        dispatch(clearInstructions());
        dispatch(setInstructions({
        title: trans('Instruction', language),
        cb: ()=> {
            dispatch(openModal({
                name: ModalName.DOCS_CREATE_CUSTOM_DASHBOARD,
                data: {}
            }))
        }
    }))
    }, [reduxUser.roles]);

    const [tableData, setTableData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [no, setNo] = useState<boolean>(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [type, setType] = useState<ICustomReport["type"]>('media_campaign_statistics');
    const [period, setPeriod] = useState<'month' | 'previous_month' | 'week' | 'previous_week' | 'choice'>('month');
    const [periodStart, setPeriodStart] = useState<string>('');
    const [periodEnd, setPeriodEnd] = useState<string>('');
    const [trgPeriod, setTrgPeriod] = useState<'none' | 'month' | 'previous_month' | 'week' | 'previous_week' | 'choice'>('none');
    const [trgPeriodStart, setTrgPeriodStart] = useState<string>('');
    const [trgPeriodEnd, setTrgPeriodEnd] = useState<string>('');
    const [group, setGroup] = useState<ICustomReport["grouping"]>('day');
    const [reportName, setReportName] = useState('');
    const [model, setModel] = useState<ICustomReport["attribution_model"]>('mtd');
    const [window, setWindow] = useState<ICustomReport["attribution_window"]>(30);
    const [noActual, setNoActual] = useState<boolean>(false);
    const [updateData, setUpdateData] = useState<boolean>(false);
    const [firstUpdateData, setFirstUpdateData] = useState<boolean>(true);

    const interactionTypes = {
        Impression: trans('Media', language),
        PageView: trans('Performance', language),
    }
    const [interactionType, setInteractionType] = useState<ICustomReport["interaction_type"]>(['Impression'])
    const [interactionTypeTitle, setInteractionTypeTitle] = useState<string>(interactionTypes['Impression'])

    const eventDates = {
        trg_event_dt: trans('trg_event_dt', language),
        event_dt: trans('event_dt', language),
    }
    const [eventDateChoice, setEventDateChoice] = useState<ICustomReport["event_dt"]>(['trg_event_dt'])
    const [eventDateTitle, setEventDateTitle] = useState<string>(eventDates['trg_event_dt'])


    const types = {
        'media_campaign_statistics': trans('media_campaign_statistics', language),
        'general_custom_table': trans('general_custom_table', language),
        'ecommerce': trans('custom_table_ecom', language),
    }

    const [listFields, setListFields] = useState<DefaultObject<string>>({});
    const [listFieldsCondition, setListFieldsCondition] = useState<DefaultObject<any>>({});
    const [listFieldsConditionValue, setListFieldsConditionValue] = useState<DefaultObject<any>>({});
    const [listFieldsConditionValueTypes, setListFieldsConditionValueTypes] = useState<DefaultObject<string>>({});
    const [listMetrics, setListMetrics] = useState<DefaultObject<string>>({});
    const [listMetricsCondition, setListMetricsCondition] = useState<DefaultObject<any>>({});
    const [listMetricsConditionValue, setListMetricsConditionValue] = useState<DefaultObject<any>>({});
    const [listMetricsConditionValueTypes, setListMetricsConditionValueTypes] = useState<DefaultObject<string>>({});
    useEffect(() => {
        if(type === 'media_campaign_statistics') {
            setListFields(fields);
            setListFieldsCondition(fieldsConditions);
            setListFieldsConditionValue(fieldsConditionValue);
            setListFieldsConditionValueTypes(fieldsConditionValueTypes);
            setListMetrics(metrics);
            setListMetricsCondition(metricsConditions);
            setListMetricsConditionValue(metricsConditionValue);
            setListMetricsConditionValueTypes(metricsConditionValueTypes);
            setDashboard('custom_table_main')
        }
        if(type === 'general_custom_table') {
            setListFields(fieldsSite);
            setListFieldsCondition(fieldsConditionsSite);
            setListFieldsConditionValue(fieldsConditionValueSite);
            setListFieldsConditionValueTypes(fieldsConditionValueTypesSite);
            setListMetrics(metricsSite);
            setListMetricsCondition(metricsConditionsSite);
            setListMetricsConditionValue(metricsConditionValueSite);
            setListMetricsConditionValueTypes(metricsConditionValueTypesSite);
            setDashboard('custom_table_general')
        }
        if(type === 'ecommerce') {
            setListFields(fieldsEcom);
            setListFieldsCondition(fieldsConditionsEcom);
            setListFieldsConditionValue(fieldsConditionValueEcom);
            setListFieldsConditionValueTypes(fieldsConditionValueTypesEcom);
            setListMetrics(metricsEcom);
            setListMetricsCondition(metricsConditionsEcom);
            setListMetricsConditionValue(metricsConditionValueEcom);
            setListMetricsConditionValueTypes(metricsConditionValueTypesEcom);
            setDashboard('custom_table_ecommerce_items')
        }
    }, [type]);

    const onChangeSelect = (response:object, setActive:any, isNumber?:boolean) => {
        setNoActual(true);
        onChangeGeneralSelect(response, setActive, () => {
            setIsDisabled(false);
        }, isNumber);
        setUpdateData(true);
    }
    const [eventTarget, setEventTarget] = useState('');
    const [datasetEventTarget, setDatasetEventTarget] = useState<any>( {'all_target_events': trans('All event targets', language)});
    const [eventTargetActive, setEventTargetActive] = useState<Array<any>>([]);
    const [filterEventTarget, setFilterEventTarget] = useState<any>( {})
    const onChangeEventTarget = (response:any) => {
        setExitCounter(true);
        for (const key in response) {
            setEventTarget(response[key] ? response[key] : trans('All event targets', language))
            setEventTargetActive([key])
        }
    }

    useEffect(() => {
        if(eventTargetActive.length > 0 && Array.isArray(reduxEvents) && reduxEvents.length > 0) {
            const temp:any = {};
            const name = eventTargetActive[0];
            reduxEvents.map((item:any)=> {
                if(item.event_target_name === name) {
                    temp.event_name = item.event_name;
                    temp.event_type = item.event_type;
                    temp.event_category = item.event_category;
                    temp.event_target_name = item.event_target_name;
                    temp.event_target_price = item.event_target_price;
                }
            })
            setFilterEventTarget(temp);
            setUpdateData(true);
        }
    }, [eventTargetActive, reduxEvents]);

    useEffect(() => {
        if(firstLoading) {
            setFirstLoading(false);
            dispatch(getProjectAttributions({currentUserId: reduxUser.id, projectId: Number(projectId)})).then((r)=> {
              if(r.payload) {
                setModel(r.payload.attribution_model);
                setWindow(r.payload.attribution_window);
              }
            })
            dispatch(getReports({projectId}))
        }
    }, [reduxUser.id]);
    useEffect(() => {
        dispatch(getEvents({projectId, calcAttr: true})).then((r) => {
            if (r.payload && Array.isArray(r.payload)) {
                const temp:any = {'all_target_events': trans('All event targets', language)};
                r.payload.map((item:any)=> (temp as any)[item.event_target_name] = item.event_target_name);
                setDatasetEventTarget(temp);
            }
        });
    }, [language]);

    const [frequency, setFrequency] = useState<ICustomReport["frequency_type"]>('one_time');
    const [isInput, setIsInput] = useState<boolean>(false);
    const [addSelect, setAddSelect] = useState(false);
    const [frequencyDay, setFrequencyDay] = useState(1);
    const [frequencyWeek, setFrequencyWeek] = useState(0);
    const [time, setTime] = useState('');
    const [method, setMethod] = useState<ICustomReport["send_method"]>('email');
    const [format, setFormat] = useState<ICustomReport["format"]>('csv');

    const frequencies = {
        'one_time': trans('One-time', language),
        'day': trans('Every day', language),
        'week': trans('Every week', language),
        'month': trans('Every month', language),
    }
    const weekdays = {
        '0': trans('mo', language),
        '1': trans('tu', language),
        '2': trans('we', language),
        '3': trans('th', language),
        '4': trans('fr', language),
        '5': trans('st', language),
        '6': trans('su', language),
    }
    const onChangeFrequency = (response:any) => {
        setExitCounter(true);
        for (const key in response) {
            if(key === 'week') {
                setFrequency(key);
                setIsInput(false);
                setAddSelect(true);
                return;
            }
            if(key === 'month') {
                setFrequency(key);
                setAddSelect(false);
                setIsInput(true);
                return;
            }
            if(key === 'one_time') {
                setTime('');
            }
            setFrequency(key);
            setIsInput(false);
            setAddSelect(false);
        }
    }
    const onChangeInput = (response:any) => {
        // setExitCounter(true);
        setFrequencyDay(response);
    }

    const [selectFields, setSelectFields] = useState<Array<any>>([{id:0}]);
    const [selectMetrics, setSelectMetrics] = useState<Array<any>>([{id:0}]);
    const onRemoveField = (id:number) => {
        const currentField = selectFields.find(item => item.id === id);
        const oldFilter = filters.find(filter => filter.name === currentField.name);
        onRemove(id, selectFields, setSelectFields);
        if(oldFilter) onRemove(oldFilter.id, filters, NewFilters);
        setNoActual(true);
        setUpdateData(true);
        setExitCounter(true);
        onCheckSort();
        onCheckFilter();
    }
    const onRemoveMetric = (id:number) => {
        const currentField = selectMetrics.find(item => item.id === id);
        const oldFilter = filtersMetric.find(filter => filter.name === currentField.name);
        onRemove(id, selectMetrics, setSelectMetrics);
        if(oldFilter) onRemove(oldFilter.id, filtersMetric, NewFiltersMetric);
        setNoActual(true);
        setUpdateData(true);
        setExitCounter(true);
        onCheckSort();
        onCheckFilter();
    }

    const onCheckSort = () => {
        if(filtersSort && filtersSort.length > 0) {
            if(selectMetrics.filter((item:any)=>item.name).length>0) {
                const names = selectMetrics.map((item)=>item.name)
                filtersSort.map((item:any) => {
                    if(!names.includes(item.name)) {
                        onRemove(item.id, filtersSort, NewFiltersSort);
                    }
                })
            }
            if(selectFields.filter((item:any)=>item.name).length>0) {
                const names = selectFields.map((item)=>item.name)
                filtersSort.map((item:any) => {
                    if(!names.includes(item.name)) {
                        onRemove(item.id, filtersSort, NewFiltersSort);
                    }
                })
            }
        }
    }
    const onCheckFilter = () => {
        if(filters && filters.length > 0) {
            if(selectMetrics.filter((item:any)=>item.name).length>0) {
                const names = selectMetrics.map((item)=>item.name)
                filters.map((item:any) => {
                    if(!names.includes(item.name)) {
                        onRemove(item.id, filters, NewFilters);
                    }
                })
            }
            if(selectFields.filter((item:any)=>item.name).length>0) {
                const names = selectFields.map((item)=>item.name)
                filters.map((item:any) => {
                    if(!names.includes(item.name)) {
                        onRemove(item.id, filters, NewFilters);
                    }
                })
            }
        }
    }

    const onChangeFilter = (response:any, id:number, filters:Array<any>, setFilters:any, setSelect:any) => {
        setExitCounter(true);
        let oldFilter:any;
        for (const key in response) {
             setSelect((prevState:any) =>
                prevState.map((item:any) => {
                  if(item.id === id) {
                      oldFilter = filters.find(filter => filter.name === item.name);
                      if(response[key])
                        return { ...item, name: key };
                      delete item.name;
                      return item;
                  }
                  return item;
                })
            );
        }
        if(oldFilter) onRemove(oldFilter.id, filters, setFilters);
    }

    const onChangeField = (response:any, id:number) => {
        let oldFilter:any;
        for (const key in response) {
             setSelectFields(prevState =>
                prevState.map(item => {
                  if(item.id === id) {
                      oldFilter = filters.find(filter => filter.name === item.name);
                      if(response[key])
                        return { ...item, name: key };
                      delete item.name;
                      return item;
                  }
                  return item;
                })
            );
        }
        if(oldFilter) onRemove(oldFilter.id, filters, NewFilters);
        if(firstUpdateData) {
            setFirstUpdateData(false);
            setUpdateData(true);
        }
        setUpdateData(true);
        setExitCounter(true);
    }
    const onChangeMetric = (response:any, id:number) => {
        let oldFilter:any;
        for (const key in response) {
             setSelectMetrics(prevState =>
                prevState.map(item => {
                  if(item.id === id) {
                      oldFilter = filtersMetric.find(filter => filter.name === item.name);
                      if(response[key])
                        return { ...item, name: key };
                      delete item.name;
                      return item;
                  }
                  return item;
                })
            );
        }
        if(oldFilter) onRemove(oldFilter.id, filtersMetric, NewFiltersMetric);
        if(firstUpdateData) {
            setFirstUpdateData(false);
            setUpdateData(true);
        }
        setUpdateData(true);
        setExitCounter(true);
    }

    const [emailList, setEmailList] = useState<Array<any>>([]);
    const onRemoveEmail = (id:number) => {
        setEmailList(emailList.filter(item => item.id !== id));
    }
    const onChangeEmail = (email:string, id:number) => {
         setEmailList(prevState =>
            prevState.map(item => {
              if(item.id === id) {
                  if(email)
                    return { ...item, email: email };
                  delete item.email;
                  return item;
              }
              return item;
            })
        );
    }
    useEffect(()=> {
        if(reportType === 'create' && reduxUser.email)
            onAddEmail(reduxUser.email);
    }, [reduxUser])

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const onHandleEmail = (data: {[index: string]: string }) => {
        const name = Object.keys(data)[0];
        if(!REG_EMAIL.test(data[name] as string))
            setEmailError(true);
        else setEmailError(false);
        setEmail(data[name]);
    }

    const onAddEmail = (email:string) => {
        const isCopy = Object.keys(filterObject(emailList, (val:any, key:any)=> val.email === email)).length > 0;
        if(!emailError && !isCopy) {
            setEmailList([...emailList, {id:emailList.length, email: email}]);
            setEmail('');
            setExitCounter(true);
        }
    }

    const prepareFilterData = (data:Array<any>, type_value?: 'array' | 'one') => {
        const result:any[]  = [];
        data.filter((item:any)=> item.name).map((item:any, index:number)=> {
            if(item.name && item.condition && item.value && item.value.length > 0 && (item.value[0] || typeof item.value[0] === 'number')) {
                let value = type_value === 'array' || typeof item.value === 'string' || typeof item.value === 'number' ? item.value : item.value[0];
                if(type_value !== 'array' && item.type === 'percent')
                    value /= 100;
                result.push({'name': item.name, 'condition':item.condition, 'value': value});
                // result.push(`name=${item.name};condition=${item.condition};value=${item.value[0]}`);
            } else if(item.name && item.value && item.value.length > 0 && (item.value[0] || typeof item.value[0] === 'number')) {
                let value = type_value === 'array' || typeof item.value === 'string' || typeof item.value === 'number' ? item.value : item.value[0];
                if(type_value !== 'array' && item.type === 'percent')
                    value /= 100;
                result.push({'name': item.name, 'condition': 'equal', 'value': value});
            }
        })

        // return result.join('&');
        return result;
    }
    const prepareSortData = (data:Array<any>) => {
        const result:{name:string, value: string}[] = [];
        data.map((item:any, index:number)=> {
            if(item.name && (item.value === 'ascending' || item.value === 'descending')) {
                result.push({'name': item.name, 'value': item.value});
            }
        })

        return result;
    }

    const on = (response:any) => {
        if (response || response==0) {
            setTableData(response);
            setIsLoading(false);
            if(response)
                setShowLoader(false);
        }
    }

    const onSubmit = ({draft = false}:{draft?: boolean}) => {
        setIsLoading(true);
        setIsDisabled(true);
        const result:ICustomReport = {
            type: type,
            name: reportName,
            period: period,
            grouping: group,
            interval_from: periodStart,
            interval_to: periodEnd,

            attribution_model: model,
            attribution_window:window,

            event_target: eventTargetActive.join(','),
            fields: selectFields.filter(item => item.name).map(item => item.name).join(','),
            filter: extendFilters ? prepareFilterData(extendFilters, 'array') : [],
            // filter: [],
            filter_fields: filters ? prepareFilterData(filters) : [],
            metrics: selectMetrics.filter(item => item.name).map(item => item.name).join(','),
            filter_metrics: filtersMetric ? prepareFilterData(filtersMetric) : [],
            sort: filtersSort ? prepareSortData(filtersSort) : [],

            frequency_type: frequency,
            frequency_weekday: frequencyWeek,
            frequency_day: frequencyDay,

            time: time,
            send_method: method,
            format: format,
            emails: emailList.map(item => item.email).join(', '),
            // draft: (frequency === 'one_time' || (frequency !== 'one_time' && time)) && method && emailList.length > 0 && !draft ? 0 : 1
            draft: isSend && !draft ? 0 : 1,

            interaction_type: interactionType,
            event_dt: eventDateChoice,
            filter_ecom: ecomFilters ? prepareFilterData(ecomFilters, 'array') : [],
            filter_trg: customFilters ? prepareFilterData(customFilters, 'one') : [],
            trg_period: trgPeriod,
            trg_interval_from: trgPeriodStart,
            trg_interval_to: trgPeriodEnd,
        }
        if(isSend) {
            if(startSend)
                result['start_send'] = startSend;
            if(endSend)
                result['end_send'] = endSend;
        } else if(!draft){
            result['not_send'] = true;
        }

        if(reportType === 'create' || reportType === 'based') {
            dispatch(createReport({data: result, projectId, lang:language}));
            sendSdk('event', {
                'event_type': 'create',
                'event_category': 'custom_reports',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId
                }
            });
        }
        if(reportType === 'edit' && reportId) {
            dispatch(updateReport({data: result, projectId, reportId, lang:language}));
            sendSdk('event', {
                'event_type': 'edit',
                'event_category': 'custom_reports',
                'event_name': 'active',
                'event_value': '10',
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'report_id': reportId
                }
            });
        }

        navigate(`/projects/${projectId}/custom-reports/`);
    }
    useEffect(()=> {
        if (reduxReports) {
            const data = reduxReports.find((item: any) => item.id === Number(reportId));
            if (data) {
                try {
                    data.type && setType(data.type);
                    data.period && setPeriod(data.period);
                    data.trg_period && setTrgPeriod(data.trg_period);
                    data.interval_from && setPeriodStart(data.interval_from);
                    data.interval_to && setPeriodEnd(data.interval_to);
                    data.trg_interval_from && setTrgPeriodStart(data.trg_interval_from);
                    data.trg_interval_to && setTrgPeriodEnd(data.trg_interval_to);
                    data.grouping && setGroup(data.grouping);
                    data.name && setReportName(data.name);
                    data.attribution_model && setModel(data.attribution_model);
                    data.attribution_window && setWindow(data.attribution_window);
                    // data.event_target && setEventTarget(data.event_target.split(','));
                    data.event_target && setEventTargetActive(data.event_target.split(','));
                    data.event_target && setEventTarget(data.event_target);

                    if(data.status !== 'notsend') {
                        setIsSend(true);
                    }

                    if (data.frequency_type) {
                        if(data.period === 'choice') {
                            setFrequency('one_time');
                            setTime('');
                            setAddSelect(false);
                            setIsInput(false);
                            setFrequencyDay(1);
                            setFrequencyWeek(0);
                        } else {
                            data.frequency_type && setFrequency(data.frequency_type);
                            if (data.frequency_day && data.frequency_type === 'month') {
                                setFrequencyDay(data.frequency_day);
                                setIsInput(true);
                            }
                            if (typeof data.frequency_weekday === 'number' && data.frequency_type === 'week') {
                                setFrequencyWeek(data.frequency_weekday);
                                setAddSelect(true);
                            }
                        }
                    }
                    data.end_send && setEndSend(data.end_send);
                    data.start_send && setStartSend(data.start_send);
                    data.time && setTime(data.time);
                    data.send_method && setMethod(data.send_method);
                    data.format && setFormat(data.format);

                    if (data.fields) {
                        const temp: any = [];
                        data.fields.split(',').map((item: any, index) => {
                            temp.push({id: index, name: item});
                        })
                        setSelectFields(temp);
                    }
                    if (typeof data.filter_fields === 'string') {
                        NewFilters(parseFilter(data.filter_fields));
                    }
                    if (typeof data.filter_ecom === 'string') {
                        NewEcomFilters(parseCustomFilter(data.filter_ecom));
                    }
                    if (typeof data.filter === 'string') {
                        NewExtendFilters(parseFilter(data.filter));
                    }
                    if (typeof data.filter_trg === 'string') {
                        NewCustomFilters(parseCustomFilterItem(data.filter_trg));
                    }
                    if (typeof data.sort === 'string') {
                        NewFiltersSort(parseSort(data.sort));
                    }
                    if(typeof data.interaction_type === 'string') {
                        const temp:any = data.interaction_type;
                        const list = temp.split('&')
                        setInteractionType(list);

                        const titles:any = [];
                        list.map((item:string) => {
                            if((interactionTypes as any)[item]) {
                                titles.push((interactionTypes as any)[item])
                            }
                        })
                        setInteractionTypeTitle(titles.join(', '));
                    }
                    if (data.metrics) {
                        const temp: any = [];
                        data.metrics.split(',').map((item: any, index) => {
                            temp.push({id: index, name: item});
                        })
                        setSelectMetrics(temp);
                    }
                    if (data.emails) {
                        const temp: any = [];
                        data.emails.split(',').map((item: any, index) => {
                            temp.push({id: index, email: item});
                        })
                        setEmailList(temp);
                    }
                    setUpdateData(true);
                } catch (e) {
                    console.log('Error - ', e);
                }
            }
        }
    }, [reduxReports])

    const [filters, NewFilters] = useState<Array<any>>([{id:0}]);
    const [filterDatasetName, SetFilterDatasetName] = useState<Array<any>>(removeObjectKeys(getFromObject({...listFields, ...listMetrics}, [...selectFields.map((item)=>item.name), ...selectMetrics.map((item)=>item.name)]), filters.map((item)=>item.name)));
    // const [filterDatasetName, SetFilterDatasetName] = useState<Array<any>>(removeObjectKeys(getFromObject(fields, selectFields.map((item)=>item.name)), filters.map((item)=>item.name)));
    // const [filterDatasetName, SetFilterDatasetName] = useState<any>({});
    const [filtersMetric, NewFiltersMetric] = useState<Array<any>>([{id:0}]);
    const [filterMetricsDatasetName, SetFilterMetricsDatasetName] = useState<Array<any>>(removeObjectKeys(getFromObject(listMetrics, selectMetrics.map((item)=>item.name)), filtersMetric.map((item)=>item.name)));

    const [filtersSort, NewFiltersSort] = useState<Array<any>>([{id:0}]);
    const [filterSortDatasetName, SetFilterSortDatasetName] = useState<Array<any>>(removeObjectKeys(getFromObject({...listFields, ...listMetrics}, [...selectFields.map((item)=>item.name), ...selectMetrics.map((item)=>item.name)]), filtersSort.map((item)=>item.name)));

    const [customFilters, NewCustomFilters] = useState<Array<any>>([{id:0}]);
    const [ecomFilters, NewEcomFilters] = useState<Array<any>>([{id:0}]);
    const [ecomFilterDatasetName, SetEcomFilterDatasetName] = useState<Array<any>>(removeObjectKeys({...fieldsEcomItems}, ecomFilters.map((item)=>item.name)));

    const [extendFilters, NewExtendFilters] = useState<Array<any>>([]);

    const addConditions = (current:any, set:any) => {
        try {
            set([...current, {id:Math.floor(Math.random() * 10000)}])
        } catch (e) {
            console.log('Error - ', e);
        }
    }

    const onHandlerChanges = (changes:any, id:number,set:any, current:any, type:string='') => {
        try {
            for(const key in changes) {
                if(key === 'name') {
                    if(changes[key]) {
                        set((prevState:any) =>
                            prevState.map((item:any) =>
                              item.id === id
                                ? { ...item, name: changes[key] }
                                : item
                            )
                        );
                    } else {
                        onRemove(id, current, set);
                    }
                }
                if(key === 'condition') {
                    set((prevState:any) =>
                        prevState.map((item:any) => {
                          if(item.id === id) {
                              if(changes[key])
                                return { ...item, condition: changes[key] };
                              delete item.condition;
                              return item;
                          }
                          return item;
                        })
                    );
                }
                if(key === 'value') {
                    set((prevState:any) =>
                        prevState.map((item:any) => {
                            try {
                                if (item.id === id) {
                                    if ((typeof changes[key] === 'string' && changes[key]) || (typeof changes[key] === 'object' && changes[key].length > 0)) {
                                        // setNoActual(true);
                                        setUpdateData(true);
                                        return {...item, value: changes[key], type: type};
                                    }
                                    delete item.value;
                                    return item;
                                }
                                return item;
                            } catch (e) {
                                console.log('Error', e);
                                return item;
                            }
                        })
                    )
                }
            }
        } catch (e) {
            console.log('Error', e);
        }
    }
    const onRemove = (id:number, current:any, set:any) => {
        onRemoveFormItem({
            id, current, set,
            cb: () => {
                setIsDisabled(false);
                setNoActual(true);
                setUpdateData(true);
            }
        })
    }

    // useEffect(()=> {
    //     const temp:any= {};
    //     selectMetrics.map((item:any)=>{
    //         temp[item.name] = (metrics as any)[item.name]
    //     })
    //     setMetricsFilter(temp);
    // }, [selectMetrics])

    useEffect(()=> {
        SetFilterDatasetName(removeObjectKeys(getFromObject({...listFields, ...listMetrics}, [...selectFields.map((item)=>item.name), ...selectMetrics.map((item)=>item.name)]), filters.map((item)=>item.name)));
        // SetFilterDatasetName(removeObjectKeys(getFromObject(fields, selectFields.map((item)=>item.name)), filters.map((item)=>item.name)));
        // setIsDisabled(false);
    }, [filters, selectFields, selectMetrics])

    useEffect(()=> {
        SetFilterMetricsDatasetName(removeObjectKeys(getFromObject(listMetrics, selectMetrics.map((item)=>item.name)), filtersMetric.map((item)=>item.name)));
        // setIsDisabled(false);
    }, [filtersMetric, selectMetrics])

    useEffect(()=> {
        SetFilterSortDatasetName(removeObjectKeys(getFromObject({...listFields, ...listMetrics}, [...selectFields.map((item)=>item.name), ...selectMetrics.map((item)=>item.name)]), filtersSort.map((item)=>item.name)));
    }, [filtersSort, selectFields, selectMetrics])

    useEffect(()=> {
        SetEcomFilterDatasetName(removeObjectKeys({...fieldsEcomItems}, ecomFilters.map((item)=>item.name)));
    }, [ecomFilters, selectFields])

    const [lastRequest, setLastRequest] = useState<DefaultObject<any>|undefined>();
    const [isRequestDone, setIsRequestDone] = useState(true);

    const sendRequest = (arg: any) => {
        dispatch(getCustomDashboards(arg)).then((r: any) => {
            if (r.payload && r.payload[dashboard]) {
                if (typeof r.payload[dashboard].error === 'string') {
                    dispatch(openPopup({type: PopupType.ERROR, description: r.payload[dashboard].error}));
                    setNo(true);
                } else {
                    try {
                        on(r.payload[dashboard]);
                        setNo(false);
                    } catch (e) {
                        on(0);
                        setNo(true);
                        console.log('Error', e);
                    }
                }
            } else {
                on(0);
                setNo(true);
            }
        }).then(()=>setIsRequestDone(true));
    }

    useEffect(()=> {
        if(updateData) {
            const interval = period !=='choice' ? getInterval(getDateAgo(new Date(), 1), period) : {interval_from: periodStart, interval_to: periodEnd};
            const trgInterval = trgPeriod !=='choice' && trgPeriod !== 'none' ? getInterval(getDateAgo(new Date(), 1), trgPeriod) : {interval_from: trgPeriodStart, interval_to: trgPeriodEnd};

            if (interval && interval.interval_from && interval.interval_to) {
                const arg: any = {
                    projectId: Number(projectId),
                    periodStart: interval.interval_from,
                    periodEnd: interval.interval_to,
                    names: [
                        dashboard
                    ],
                    type: type,
                    model: model,
                    window: window,
                    filter: extendFilters ? prepareFilterData(extendFilters, 'array') : [],
                    filter_fields: filters ? prepareFilterData(filters) : [],
                    categorize: group,
                    targetEvents: filterEventTarget,
                    aggregation: selectFields.filter(item => item.name).map(item => item.name),
                    metrics: selectMetrics.filter(item => item.name).map(item => item.name),
                    filter_metrics: filtersMetric ? prepareFilterData(filtersMetric) : [],
                    // aggregation: Object.keys(fields),
                    sort: filtersSort ? prepareSortData(filtersSort) : [],
                    // filter_fields: tableFilter,
                    limit: 100,
                    metrics_include: true,
                    interaction_type: interactionType,
                    event_dt: eventDateChoice,
                    filter_ecom: ecomFilters ? prepareFilterData(ecomFilters, 'array') : [],
                    filter_trg: customFilters ? prepareFilterData(customFilters, 'one') : [],
                    trgPeriodStart: trgInterval ? trgInterval.interval_from : undefined,
                    trgPeriodEnd: trgInterval ? trgInterval.interval_to : undefined,
                    // offset: offset,
                    // defaultReq: defaultRequest
                }

                if((type === 'general_custom_table' || type === 'ecommerce' ? arg.aggregation.length > 0 && arg.metrics.length > 0 : arg.aggregation.length > 0 || arg.metrics.length > 0)) {
                    setShowLoader(true);
                    setIsLoading(true);
                    setLastRequest(arg);
                }
            }
        }
        setNoActual(false);
        setUpdateData(false);
    }, [updateData])

    useEffect(() => {
        if(isRequestDone && lastRequest) {
            setIsRequestDone(false);
            setLastRequest(undefined);
            sendRequest(lastRequest);
        }
    }, [isRequestDone, lastRequest]);

    useEffect(()=> {
        if(!firstUpdateData && (selectMetrics.filter((item:any)=>item.name).length>0 || selectFields.filter((item:any)=>item.name).length>0)) {
            // setNoActual(true);
            // setUpdateData(true);
        }
    }, [group, model, period, selectFields, selectMetrics, window])

    const [range, setRange] = useState<DateRange>();
    const [trgRange, setTrgRange] = useState<DateRange>();
    const [startSend, setStartSend] = useState<string>('');
    const [endSend, setEndSend] = useState<string>('');
    const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
    const [isTrgPopperOpen, setIsTrgPopperOpen] = useState<boolean>(false);
    const handleRangeSelect = (range: DateRange) => {
        setRange(range);
        let start = '';
        let end = '';
        if (range.from && range.to) {
            const data = {
                start: formatDate(range.from),
                end: formatDate(range.to)
            }
            start = data.start;
            end = data.end;
            setIsPopperOpen(false);
            setRange(undefined);
            onChangeSelect({'choice': `${start} - ${end}`}, setPeriod);
            setFrequency('one_time');
            setTime('');
            setAddSelect(false);
            setIsInput(false);
            setFrequencyDay(1);
            setFrequencyWeek(0);
            setExitCounter(true);
        }
        setPeriodStart(start);
        setPeriodEnd(end);
    }
    const handleTrgRangeSelect = (range: DateRange) => {
        setTrgRange(range);
        let start = '';
        let end = '';
        if (range.from && range.to) {
            const data = {
                start: formatDate(range.from),
                end: formatDate(range.to)
            }
            start = data.start;
            end = data.end;
            setIsTrgPopperOpen(false);
            setTrgRange(undefined);
            onChangeSelect({'choice': `${start} - ${end}`}, setTrgPeriod);
            setFrequency('one_time');
            setTime('');
            setAddSelect(false);
            setIsInput(false);
            setFrequencyDay(1);
            setFrequencyWeek(0);
            setExitCounter(true);
        }
        setTrgPeriodStart(start);
        setTrgPeriodEnd(end);
    }

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(exitCounter)
    if(showPrompt && reportType !== 'edit') {
        dispatch(openModal({
            name: ModalName.CONFIRM_EXIT,
            data: {
                title: trans('Confirmation of leaving the page', language),
                confirmText: 'Save',
                message: 'You have unsaved changes. Save as a draft?',
                confirm: () => {
                    if(typeof confirmNavigation === 'function') confirmNavigation();
                    onSubmit({draft: true})
                },
                cancel: confirmNavigation
            }
        }))
    } else if(showPrompt && typeof confirmNavigation === 'function') confirmNavigation();

    const [isSend, setIsSend] = useState(false);

    const dayPickerRef = useRef(null);
    useOnClickOutside(() => {
        if (isPopperOpen) {
            setIsPopperOpen(false);
        }
    }, [dayPickerRef]);

    const dayTrgPickerRef = useRef(null);
    useOnClickOutside(() => {
        if (isTrgPopperOpen) {
            setIsTrgPopperOpen(false);
        }
    }, [dayTrgPickerRef]);

    const [isSubmit, setIsSubmit] = useState(false);
    useEffect(() => {
        if(isSubmit) {
            onSubmit({});
        }
    }, [isSubmit]);

    const handleDragEnd = (event: DragEndEvent, set: any) => {
        const {active, over} = event;

        if (over && active.id !== over.id) {
            set((items:Array<any>) => {
                const oldIndex = items.findIndex((item) => item.id === active.id);
                const newIndex = items.findIndex((item) => item.id === over.id);

                return arrayMove(items, oldIndex, newIndex);
            });
            setUpdateData(true);
        }
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

  return (
      <>
        <div className="reports-create" ref={ref}>
            <div className="reports-create__block">
                <h1>{trans('Create a report', language)}</h1>
                <p className='reports-create__description'>{trans('To create a report, fill in the fields below', language)}</p>
                <div className="reports-create__base">
                    <div className="reports-create__field">
                        <Input
                            type={'text'}
                            name={'report_name'}
                            label={trans('Report name', language)}
                            value={reportName}
                            placeholder={trans('Input name', language)}
                            onChange={(obj) => {
                                setReportName(obj['report_name']);
                                setIsDisabled(false);
                                setExitCounter(true);
                            }}
                            required={true}
                        />
                    </div>
                    <div className="reports-create__field" ref={dayPickerRef}>
                        <Select
                            selectActive={(Periods(language) as any)[period] ? (Periods(language) as any)[period] : period === 'choice' && periodStart && periodEnd ? `${periodStart} - ${periodEnd}` : trans('Select the period', language)}
                            dataset={Object.assign(Periods(language), {'choice': trans('Select a range', language)})}
                            onChangeActive={(r: any) => {
                                setExitCounter(true);
                                if (r['choice']) {
                                    setIsPopperOpen(true);
                                    setFrequency('one_time');
                                    setTime('');
                                    setAddSelect(false);
                                    setIsInput(false);
                                    setFrequencyDay(1);
                                    setFrequencyWeek(0);
                                    return;
                                }
                                NewEcomFilters([]);
                                onChangeSelect(r, setPeriod)
                            }}
                            label={trans(type === 'ecommerce'? 'Period of interaction' : 'Period', language)}
                            colorText={'dark'}
                            required={true}
                        />
                        {
                            isPopperOpen &&
                            <div className="custom-input__rdp">
                                <div className="custom-input__rdp-inner">
                                    <DayPicker
                                        mode="range"
                                        defaultMonth={range?.from}
                                        selected={range}
                                        locale={ru}
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        onSelect={handleRangeSelect}
                                        fromMonth={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                                        toDate={new Date()}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="reports-create__field">
                        <Select
                            selectActive={(Groups(language) as any)[group] ? (Groups(language) as any)[group] : trans('Select the group', language)}
                            dataset={Groups(language)}
                            onChangeActive={(r: any) => {
                                onChangeSelect(r, setGroup);
                                setExitCounter(true);
                            }}
                            label={trans('Group', language)}
                            colorText={'dark'}
                            required={true}
                        />
                    </div>
                    <div className="reports-create__field">
                        <Select
                            selectActive={types[type] ? types[type] : trans('Select the type', language)}
                            dataset={types}
                            onChangeActive={(r: any) => {
                                onChangeSelect(r, setType);
                                on(0);
                                setNo(false);
                                setSelectFields([]);
                                setSelectMetrics([]);
                                NewFilters([]);
                                NewFiltersMetric([]);
                                NewFiltersSort([]);
                                NewCustomFilters([]);
                                NewEcomFilters([]);
                                NewExtendFilters([]);
                            }}
                            label={trans('Report type', language)}
                            colorText={'dark'}
                            required={true}
                        />
                    </div>
                    {type === 'ecommerce' &&
                        <div className="reports-create__field">
                            <Select
                                selectActive={interactionTypeTitle ? interactionTypeTitle : trans('Select the interaction type', language)}
                                dataset={interactionTypes}
                                onChangeActive={(r: any) => {
                                    const {title, checked} = onChangeGeneralCheckbox(r);
                                    setInteractionType(checked);
                                    setInteractionTypeTitle(title);
                                    setExitCounter(true);
                                    NewEcomFilters([]);
                                    setUpdateData(true);
                                }}
                                label={trans('Interaction type', language)}
                                colorText={'dark'}
                                required={true}
                                checkbox={true}
                                className={'custom-input--search'}
                                position={'right'}
                                maxChecked={2}
                                minChecked={1}
                                listActive={(interactionType as any)}
                            />
                        </div>
                    }
                    {type === 'ecommerce' &&
                        <div className="reports-create__field" ref={dayTrgPickerRef}>
                            <Select
                                selectActive={(Periods(language) as any)[trgPeriod] ? (Periods(language) as any)[trgPeriod] : trgPeriod === 'choice' && trgPeriodStart && trgPeriodEnd ? `${trgPeriodStart} - ${trgPeriodEnd}` : trans('Не выбрано', language)}
                                dataset={Object.assign({'none': trans('Not selected', language)}, Periods(language), {'choice': trans('Select a range', language)})}
                                onChangeActive={(r: any) => {
                                    setExitCounter(true);
                                    if (r['choice']) {
                                        setIsTrgPopperOpen(true);
                                        setFrequency('one_time');
                                        setTime('');
                                        setAddSelect(false);
                                        setIsInput(false);
                                        setFrequencyDay(1);
                                        setFrequencyWeek(0);
                                        return;
                                    }
                                    NewEcomFilters([]);
                                    setUpdateData(true);
                                    onChangeSelect(r, setTrgPeriod)
                                }}
                                label={trans('Period of event target', language)}
                                colorText={'dark'}
                            />
                            {
                                isTrgPopperOpen &&
                                <div className="custom-input__rdp">
                                    <div className="custom-input__rdp-inner">
                                        <DayPicker
                                            mode="range"
                                            defaultMonth={trgRange?.from}
                                            selected={trgRange}
                                            locale={ru}
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            onSelect={handleTrgRangeSelect}
                                            fromMonth={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                                            toDate={new Date()}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>


                {(type === 'general_custom_table' || type === 'ecommerce') && <div className={`reports-create-box ${type === 'ecommerce' ? 'reports-create-box__settings' : ''}`}>
                    <h3>{trans('Target events settings', language)}</h3>
                    <div className="reports-create-box__block">
                        <div className="reports-create__field">
                            <Select
                                selectActive={(Models(language) as any)[model]}
                                dataset={Models(language)}
                                onChangeActive={(r: any) => onChangeSelect(r, setModel)}
                                colorText={'dark'}
                            />
                        </div>
                        <div className="reports-create__field">
                            <Select
                                selectActive={`${trans('Attribution window', language)}: ${window} ${trans('days', language)}`}
                                dataset={Windows(language)}
                                onChangeActive={(r: any) => onChangeSelect(r, setWindow)}
                                colorText={'dark'}
                            />
                        </div>
                        {type === 'general_custom_table' &&
                            <div className="reports-create__field reports-create__field_last">
                                <Select
                                    selectActive={eventTarget ? eventTarget : trans('All event targets', language)}
                                    dataset={datasetEventTarget}
                                    onChangeActive={(r: any) => {
                                        onChangeEventTarget(r);
                                    }}
                                    colorText={'dark'}
                                    // checkbox={true}
                                    listActive={eventTargetActive}
                                />
                            </div>}
                    </div>
                </div>}


                {
                    <>
                        {type && <div className="reports-create-box">
                            <h3>{trans('Fields', language)}<span>*</span></h3>
                            <div className="reports-create-box__block reports-create-box__block_start">
                                <DndContext sensors={sensors} onDragEnd={(e)=>handleDragEnd(e, setSelectFields)}>
                                    <SortableContext items={selectFields}>
                                        {
                                            selectFields.map((item, index) => {
                                                return (
                                                    <FieldItem
                                                        key={index}
                                                        item={item}
                                                        dataset={removeObjectKeys(listFields, selectFields.map((item)=>item.name))}
                                                        datasetBase={listFields}
                                                        defaultActive={trans('Select the field', language)}
                                                        onRemove={onRemoveField}
                                                        onChange={onChangeField}
                                                    />
                                                )
                                            })
                                        }
                                    </SortableContext>
                                </DndContext>
                            <Btn
                                className='reports-create-box__btn-add'
                                text={trans('Add field', language)}
                                icon={{
                                    Component: PlusIcon,
                                    width: 12,
                                    height: 12,
                                }}
                                color='border-blue'
                                onClick={()=>setSelectFields([...selectFields, {id:Math.floor(Math.random() * 10000)}])}
                            />
                        </div>
                    </div>}
                    {type && <div className="reports-create-box">
                        <h3>{trans('Metrics', language)}<span>*</span></h3>
                        <div className="reports-create-box__block reports-create-box__block_start">
                            <DndContext sensors={sensors} onDragEnd={(e)=>handleDragEnd(e, setSelectMetrics)}>
                                <SortableContext items={selectMetrics}>
                                    {
                                        selectMetrics.map((item, index)=>{
                                            return(
                                                <FieldItem
                                                    key={index}
                                                    item={item}
                                                    dataset={removeObjectKeys(listMetrics, selectMetrics.map((item)=>item.name))}
                                                    datasetBase={listMetrics}
                                                    defaultActive={trans('Select the metric', language)}
                                                    onRemove={onRemoveMetric}
                                                    onChange={onChangeMetric}
                                                />
                                            )
                                        })
                                    }
                                </SortableContext>
                            </DndContext>
                            <Btn
                                className='reports-create-box__btn-add'
                                text={trans('Add metric', language)}
                                icon={{
                                    Component: PlusIcon,
                                    width: 12,
                                    height: 12,
                                }}
                                color='border-blue'
                                onClick={()=>setSelectMetrics([...selectMetrics, {id:Math.floor(Math.random() * 10000)}])}
                            />
                        </div>
                    </div>}
                    {(selectMetrics.filter((item:any)=>item.name).length>0 || selectFields.filter((item:any)=>item.name).length>0)  && <div className="reports-create-box">
                        <h3>{type === 'ecommerce' ? trans('Filter by values', language) : trans('Filter', language)}</h3>
                        <div className="reports-create-box__block">
                            {filters && filters.length > 0 && filters.map((filter:any) => <FilterItem
                                key={filter.id} {...filter}
                                projectId={projectId}
                                filterName={filter.name}
                                filterCondition={filter.condition}
                                filterValue={filter.value}
                                onChange={(changes:any, id:number, type?:string)=> {
                                    onHandlerChanges(changes, id, NewFilters, filters, type);
                                    setExitCounter(true);
                                }}
                                onRemove={(id:number)=>{
                                    onRemove(id, filters, NewFilters);
                                    setExitCounter(true);
                                }}
                                datasetName={filterDatasetName}
                                datasetNameBase={{...listFields, ...listMetrics}}
                                datasetConditions={{...listFieldsCondition, ...listMetricsCondition}}
                                datasetValue={{...listFieldsConditionValue, ...listMetricsConditionValue}}
                                datasetValueTypes={{...listFieldsConditionValueTypes, ...listMetricsConditionValueTypes}}
                                isCheckbox={false}
                                isDelay={true}
                            />)}

                            <div className="filter-dashboards__add">
                                <Btn
                                  className='custom-select__add-btn'
                                  text={trans('Add', language)}
                                  icon={{
                                    Component: PlusIcon,
                                    width: 12,
                                    height: 12,
                                  }}
                                  widthAuto={true}
                                  color='dark'
                                  onClick={()=>addConditions(filters, NewFilters)}
                                />
                            </div>
                        </div>
                    </div>}
                    {(selectMetrics.filter((item:any)=>item.name).length>0 || selectFields.filter((item:any)=>item.name).length>0) && <div className="reports-create-box">
                        <h3>{trans('Filter by media campaigns', language)}</h3>
                        <div className="reports-create-box__block">
                            {extendFilters && extendFilters.length > 0 && extendFilters.map((filter:any) => <FilterItem
                                key={filter.id} {...filter}
                                projectId={projectId}
                                filterName={filter.name}
                                filterCondition={filter.condition}
                                filterValue={filter.value ? filter.value : []}
                                onChange={(changes:any, id:number)=> {
                                    onHandlerChanges(changes, id, NewExtendFilters, extendFilters);
                                    setExitCounter(true);
                                }}
                                onRemove={(id:number)=>{
                                    onRemove(id, extendFilters, NewExtendFilters);
                                    setExitCounter(true);
                                }}
                                datasetName={removeObjectKeys(Object.assign(datasetName), extendFilters.map((item)=>item.name))}
                                datasetNameBase={Object.assign(datasetName)}
                                datasetConditions={Object.assign(datasetConditions)}
                                datasetValue={Object.assign(datasetValue)}
                            />)}

                            <div className="filter-dashboards__add">
                                    <Btn
                                      className='custom-select__add-btn'
                                      text={trans('Add', language)}
                                      icon={{
                                        Component: PlusIcon,
                                        width: 12,
                                        height: 12,
                                      }}
                                      widthAuto={true}
                                      color='dark'
                                      onClick={()=>addConditions(extendFilters, NewExtendFilters)}
                                    />
                            </div>
                        </div>
                    </div>}
                    {(type === 'ecommerce') && (selectMetrics.filter((item:any)=>item.name).length>0 || selectFields.filter((item:any)=>item.name).length>0) && <div className="reports-create-box">
                        <h3>{trans('Custom filter', language)}</h3>
                        <div className="reports-create-box__block">
                            {customFilters && customFilters.length > 0 && customFilters.map((filter:any) => <CustomFilterItem
                                    key={filter.id} {...filter}
                                    projectId={projectId}
                                    filterName={filter.name}
                                    filterValue={filter.value}
                                    onChange={(changes:any, id:number)=> {
                                        onHandlerChanges(changes, id, NewCustomFilters, customFilters);
                                        setExitCounter(true);
                                    }}
                                    onRemove={(id:number)=>{
                                        onRemove(id, customFilters, NewCustomFilters);
                                        setExitCounter(true);
                                    }}
                                    isDelay={true}
                                />)}

                                {customFilters.length < 5 && <div className="filter-dashboards__add">
                                    <Btn
                                      className='custom-select__add-btn'
                                      text={trans('Add', language)}
                                      icon={{
                                        Component: PlusIcon,
                                        width: 12,
                                        height: 12,
                                      }}
                                      widthAuto={true}
                                      color='dark'
                                      onClick={()=>addConditions(customFilters, NewCustomFilters)}
                                    />
                                </div>}
                        </div>
                    </div>}
                    {(type === 'ecommerce') && (selectMetrics.filter((item:any)=>item.name).length>0 || selectFields.filter((item:any)=>item.name).length>0) && <div className="reports-create-box">
                        <h3>{trans('Ecommerce filter', language)}</h3>
                        <div className="reports-create-box__block">
                            {ecomFilters && ecomFilters.length > 0 && ecomFilters.map((filter:any) => <EcomFilterItem
                                key={filter.id} {...filter}
                                projectId={projectId}
                                filterName={filter.name}
                                filterValue={filter.value}
                                onChange={(changes:any, id:number, type?:string)=> {
                                    onHandlerChanges(changes, id, NewEcomFilters, ecomFilters, type);
                                    setExitCounter(true);
                                }}
                                onRemove={(id:number)=>{
                                    onRemove(id, ecomFilters, NewEcomFilters);
                                    setExitCounter(true);
                                }}
                                datasetName={ecomFilterDatasetName}
                                datasetNameBase={{...fieldsEcomItems}}
                                isCheckbox={true}
                                model={model}
                                window={window}
                                interval={period !=='choice' ? getInterval(getDateAgo(new Date(), 1), period) : {interval_from: periodStart, interval_to: periodEnd}}
                                trgInterval={trgPeriod !=='choice' && trgPeriod !== 'none' ? getInterval(getDateAgo(new Date(), 1), trgPeriod) : {interval_from: trgPeriodStart, interval_to: trgPeriodEnd}}
                                interaction_type={interactionType}
                                event_dt={eventDateChoice}
                                selectFields={getFromObject({...selectFields}, [...Object.keys(fieldsEcomItems)])}
                                // isDelay={true}
                            />)}

                            {ecomFilters.length < 10 && <div className="filter-dashboards__add">
                                    <Btn
                                      className='custom-select__add-btn'
                                      text={trans('Add', language)}
                                      icon={{
                                        Component: PlusIcon,
                                        width: 12,
                                        height: 12,
                                      }}
                                      widthAuto={true}
                                      color='dark'
                                      onClick={()=>addConditions(ecomFilters, NewEcomFilters)}
                                    />
                            </div>}
                        </div>
                    </div>}
                    {(selectMetrics.filter((item:any)=>item.name).length>0 || selectFields.filter((item:any)=>item.name).length>0) && <div className="reports-create-box">
                        <h3>{trans('Sorting', language)}</h3>
                        <div className="reports-create-box__block">
                            {filtersSort && filtersSort.length > 0 && filtersSort.map((filter:any) => <SortItem
                                key={filter.id} {...filter}
                                filterName={filter.name}
                                filterValue={trans(filter.value, language)}
                                onChange={(changes:any, id:number)=> {
                                    onHandlerChanges(changes, id, NewFiltersSort, filtersSort);
                                    setExitCounter(true);
                                }}
                                onRemove={(id:number)=>{
                                    onRemove(id, filtersSort, NewFiltersSort);
                                    setExitCounter(true);
                                }}
                                datasetName={filterSortDatasetName}
                                datasetNameBase={{...listFields, ...listMetrics}}
                            />)}

                            {filtersSort.length < 3 && <div className="filter-dashboards__add">
                                <Btn
                                  className='custom-select__add-btn'
                                  text={trans('Add', language)}
                                  icon={{
                                    Component: PlusIcon,
                                    width: 12,
                                    height: 12,
                                  }}
                                  widthAuto={true}
                                  color='dark'
                                  onClick={()=>addConditions(filtersSort, NewFiltersSort)}
                                />
                            </div>}
                        </div>
                    </div>}

                    <div className="reports-create-box">
                        <Toggle
                            name={'is-send-report'}
                            isChecked={isSend}
                            label={'Отправка отчета на почту'}
                            onChange={(evt:any)=> {
                                setIsSend(evt.currentTarget && evt.currentTarget.checked)
                            }}
                        />
                    </div>


                    {isSend && <div className="reports-create-box">
                        <h3>{trans('Send settings', language)}</h3>
                        <div className="reports-create-box__block">
                            <div className="reports-create__field">
                                {addSelect ?
                                    <div className='reports-create-box__add'>
                                    <span className="reports-create-box__title">
                                        {trans('Frequency', language)}
                                    </span>
                                        <div className="reports-create-box__add-select">
                                            {trans('Every week', language)} в
                                            <Select
                                                selectActive={(weekdays as any)[`${frequencyWeek}`]}
                                                dataset={weekdays}
                                                onChangeActive={(r: any) => {
                                                    onChangeSelect(r, setFrequencyWeek, true);
                                                    setExitCounter(true);
                                                }}
                                                color={'dark'}
                                                widthAuto={true}
                                            />
                                            <button
                                                className="reports-create-box__add-remove"
                                                onClick={() => {
                                                    setFrequency('one_time');
                                                    setTime('');
                                                    setIsInput(false);
                                                    setFrequencyDay(1);
                                                    setFrequencyWeek(0);
                                                    setAddSelect(false);
                                                    setExitCounter(true);
                                                }}
                                            >
                                                <img src={CloseIcon} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                    :
                                    <Select
                                        label={trans('Frequency', language)}
                                        selectActive={(frequencies as any)[frequency] ? (frequencies as any)[frequency] : trans('Select the frequency', language)}
                                        dataset={frequencies}
                                        onChangeActive={onChangeFrequency}
                                        colorText={'dark'}
                                        input={isInput}
                                        onInput={onChangeInput}
                                        isDisabled={period === 'choice'}
                                        inputConfig={{
                                            before: trans('Every month', language),
                                            after: trans('day_numbers', language),
                                            limit: 30,
                                            default: frequencyDay
                                        }}
                                    />
                                }
                            </div>
                            <div className="reports-create__field">
                                <Select
                                    label={trans('Send time', language)}
                                    selectActive={time ? time : trans('Select the time', language)}
                                    dataset={TIMES}
                                    onChangeActive={(r: any) => {
                                        onChangeSelect(r, setTime);
                                        setExitCounter(true);
                                    }}
                                    colorText={'dark'}
                                    isDisabled={frequency === 'one_time'}
                                />
                            </div>
                            {frequency !== 'one_time' && <div className="reports-create__field">
                                <Input
                                    name={'start-send'}
                                    value={startSend}
                                    label={trans('Начало отправки', language)}
                                    placeholder={'____-__-__'}
                                    isDateField={true}
                                    dateSettings={{
                                        min: new Date,
                                        max: new Date(new Date().setMonth(new Date().getMonth() + 5))
                                    }}
                                    onChange={(form) => {
                                        if(form['start-send'] && isValidDateFormat(form['start-send'])) {
                                            if(form['start-send'] < formatDate(new Date())) {
                                                setStartSend('');
                                                return;
                                            }
                                        }
                                        setStartSend(form['start-send']);
                                        if(isValidDateFormat(form['start-send']) && isValidDateFormat(endSend)) {
                                            if(form['start-send'] > endSend) {
                                                setEndSend('');
                                            }
                                            if(calculateMonthsBetweenDates(form['start-send'], endSend) > 6) {
                                                setEndSend('');
                                            }
                                        }

                                    }}
                                    isIndentRight={false}
                                />
                            </div>}
                            {frequency !== 'one_time' && <div className="reports-create__field">
                                <Input
                                    name={'end-send'}
                                    value={endSend}
                                    label={trans('Окончание отправки', language)}
                                    placeholder={'____-__-__'}
                                    isDateField={true}
                                    onChange={(form) => {
                                        if(isValidDateFormat(form['end-send'])) {
                                            const maxDate = startSend ? new Date(new Date(startSend).setMonth(new Date(startSend).getMonth() + 5)) : new Date(new Date().setMonth(new Date().getMonth() + 5));
                                            const maxDateWithLastDayInMonth = new Date(maxDate.getFullYear(), maxDate.getMonth()+1, 0);
                                            if(form['end-send'] > formatDate(maxDateWithLastDayInMonth)) {
                                                setEndSend('');
                                                return;
                                            }
                                            if(form['end-send'] < formatDate(isValidDateFormat(startSend) ? new Date(startSend) : new Date())) {
                                                setEndSend('');
                                                return;
                                            }
                                        }
                                        setEndSend(form['end-send']);
                                    }}
                                    required={true}
                                    isIndentRight={false}
                                    dateSettings={{
                                        min: startSend ? new Date(startSend) : new Date(),
                                        max: startSend ? new Date(new Date(startSend).setMonth(new Date(startSend).getMonth() + 5)) : new Date(new Date().setMonth(new Date().getMonth() + 5))
                                    }}
                                />
                            </div>}
                            <div className="reports-create__field">
                                <Select
                                    label={trans('Method of sending', language)}
                                    selectActive={method ? trans(method, language) : trans('Select the method', language)}
                                    dataset={{'email': 'Email'}}
                                    onChangeActive={(r: any) => {
                                        onChangeSelect(r, setMethod);
                                        setExitCounter(true);
                                    }}
                                    colorText={'dark'}
                                />
                            </div>
                            <div className="reports-create__field">
                                <Select
                                    label={trans('Data format', language)}
                                    selectActive={format ? trans(format, language) : trans('Select the format', language)}
                                    dataset={{
                                        // 'xlsx':'Excel',
                                        'csv': 'Csv'
                                    }}
                                    onChangeActive={(r: any) => {
                                        onChangeSelect(r, setFormat);
                                        setExitCounter(true);
                                    }}
                                    colorText={'dark'}
                                />
                            </div>
                            <div className="reports-create-email">
                                <span className="reports-create-box__title">
                                    {trans('Recipients', language)}
                                </span>
                                <div className="reports-create-email__wrapper">
                                    {
                                        emailList.map((item, index) => {
                                            return (
                                                <div key={index} className='reports-create-email__item'>
                                                    <div className='reports-create-email__title'>{item.email}</div>
                                                    <button
                                                        onClick={() => {
                                                            onRemoveEmail(item.id);
                                                            setExitCounter(true);
                                                        }}
                                                        className='reports-create-email__remove'>
                                                        <img src={whiteClose} alt=""/>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <div className='reports-create-email-add'>
                                    <Input
                                        type={'text'}
                                        name={'add-email'}
                                        value={email}
                                        placeholder={trans('Enter mail', language)}
                                        errorLabel={trans('Enter the correct E-mail', language)}
                                        isInvalid={emailError}
                                        onChange={onHandleEmail}
                                    />
                                    <button
                                        className='reports-create-email-add__btn'
                                        onClick={() => onAddEmail(email)}
                                    >
                                        <PlusIcon/>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>}
                </>
                }

                <Btn
                    name='submit-btn'
                    type={"button"}
                    isfullWidth={true}
                    text={reportType === 'edit' ? trans('Update report', language) : trans('Create report', language)}
                    color='blue'
                    onClick={() => {
                        setExitCounter(false);
                        setIsSubmit(true);
                    }}
                    disabled={!(reportName && !isDisabled && (selectFields.filter((item: any) => item.name).length > 0 || selectMetrics.filter((item: any) => item.name).length > 0) && emailList.length > 0 && (!isSend || (isSend && ((frequency !== 'one_time' && time && endSend) || frequency === 'one_time'))))}
                />
            </div>
            <div className="reports-create-preview">

            {
                    !isLoading && tableData && (selectFields.filter((item: any) => item.name).length > 0 || selectMetrics.filter((item: any) => item.name).length > 0) ?
                        <TablePagination
                            data={tableData.data.dataset}
                            title={tableData.data.column.map((item: any) => item.name)}
                            subDescription={trans('Prepreview', language)}
                            columnPreferences={tableData.data.column}
                            fieldTypes={tableData.data.column.map((item: any) => item.type)}
                            sortFront={true}
                            serverGroup={true}
                            valueSearch={''}
                            loader={showLoader}
                            dateGrouping={group}
                            name={reportName}
                            aggColIndexes={[...selectFields.map((item)=>item.name), ...selectMetrics.map((item)=>item.name)].map((item:any) => tableData.data.column.findIndex((el:any) => el.name === item))}
                            aggFunctions={tableData.data.column.map((item:any)=>item.agg_function)}
                            offFilter={true}
                            offAgg={true}
                            hideTotal={true}
                    /> : no && reportName && (selectFields.filter((item:any)=>item.name).length>0 || selectMetrics.filter((item:any)=>item.name).length>0)  ?
                    <TablePagination data={[]} title={[]} fieldTypes={[]} sortFront={true} loader={false} name={reportName} onRepeat={()=> {
                        // onSubmit({})
                        setUpdateData(true);
                        // setFirstLoading(false);
                        setNo(false);
                        // setIsLoading(true);
                    }}/> : isLoading ? <Loader inBlock={true}/> :
                    <>
                        <img className='reports-create-preview__img' src={iconDocuments} alt=""/>
                        <p>{
                            type === 'general_custom_table' || type === 'ecommerce' ? trans('Select at least one field and one metric, and a preview of your report will be displayed here', language) :
                                trans('A preview of your report will appear here', language)
                        }</p>
                    </>
                }
                {noActual && (selectFields.filter((item:any)=>item.name).length>0 || selectMetrics.filter((item:any)=>item.name).length>0) &&
                    <div className='table-server-update'>
                        <div className="table-server-update__block">
                            <p>{trans('The data is not up to date', language)}</p>
                            <Btn
                                text={trans('Update', language)}
                                color={'light-blue'}
                                onClick={()=> {
                                    setUpdateData(true);
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    </>
  );
};


export default ReportUpdate;