import {FC, useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";

import {projectGeneral} from "../../../redux/slices/project";
import {currentUserLang} from "../../../redux/slices/user";

import {trans} from "../../../_locales";
import {useOnClickOutside} from "../../../helpers";
import {
    convertArraysToCsv,
    downloadCsv,
    findMaxValue,
    formattingValues,
    generateAndDownloadXlsx,
    isNumber, prepareArraysToXlsx
} from '../../../utils';
import {
    groupByIndex,
    getCountPage,
    aggregateData,
    getStringColIndexes,
    getFuncIndexes,
    onChangeSortTableCustom,
    getTotalValues,
    getColorValue,
    getMaxTargetPrice,
    handleMouseMove, downloadReport, changeFilter
} from "./functions";

import {ITablePagination} from "./interfaces";

import settingIcon from '../../../assets/icons/icon-setting.svg';
import { ReactComponent as FullScreenIcon } from '../../../assets/icons/fullscreen.svg'
import { ReactComponent as FullScreenExitIcon } from '../../../assets/icons/fullscreen-exit.svg'
import { ReactComponent as DotsIcon } from '../../../assets/icons/icon-dots-black.svg';
import { ReactComponent as DownloadIcon} from '../../../assets/icons/download.svg';

import Btn from "../../../ui/Btn";
import Pagination from "../../ui/Pagination";
import Select from "../../ui/Select";

import TablePaginationHeadItem from "./Head";
import Tooltip from "../../Tooltip";
import Loader from "../../Loader";
import Empty from "../../Empty";

import './style.scss';
import Alt from "../../Alt";
import {eventsData} from "../../../redux/slices/events";
import TablePaginationHeadStretchItem from "./HeadStreatch";
import {DefaultObject} from "../../../interfaces/common.d";
import DashboardOutputValue from "./OutputValue";
import {getMinLengthTable, handlePin, onPin} from "../functions";

const TablePagination: FC<ITablePagination> = ({
    data = [],
    title ,
    subDescription,
    columnPreferences=[],
    fieldTypes,
    sortFront=true ,
    valueSearch='',
    onChangeLimitChannel,
    pageCount,
    loader= true,
    dateGrouping,
    name,
    disableName = false,
    tooltip,
    onRepeat,
    aggFunctions=[],
    aggColIndexes=[],
    aggChoice=[],
    metricColIndexesDefault=[],
    metricChoice=[],
    offFilter=false,
    serverGroup=false,
    targetEvents,
    stretch,
    offAgg=false,
    tabs,
    activeTab,
    onChangeTab,
    isFullScreen=false,
    onOpenFullScreen,
    onCloseFullScreen,
    isBigData=false,
    defaultLimit=10,
    defaultSort={},
    defaultSearch={},
    defaultFilter={},
    onChangeActiveFilter=(val:any)=>null,
    onChangeActiveSearch=(val:any)=>null,
    onChangeActiveSort=(val:any)=>null,
    hideTotal=false,
    settings,
    onChangeSettings,
    posTooltip
}) => {
    const generalProjectInfo = useAppSelector(projectGeneral);
    const language = useAppSelector(currentUserLang);
    const reduxEvents = useAppSelector(eventsData);
    const currency= generalProjectInfo && generalProjectInfo.currency || 'USD';

    const [dataWrapper, setDataWrapper] = useState<Array<any>>([]);
    const [dataReady, setDataReady] = useState(false);
    const [dataCopy, setDataCopy] = useState<Array<any>>(JSON.parse(JSON.stringify(data)));
    const [itemCountPercent, setItemCountPercent] = useState<Array<any>>([]);
    const [itemCountPercentCopy, setItemCountPercentCopy] = useState<Array<any>>([]);
    const [dataTotal, setDataTotal] = useState<Array<any>>([]);
    const [countTotal, setCountTotal] = useState<number>(1);
    const [sumNotIncludeData, setSumNotIncludeData] = useState<any>({});
    const [countNotIncludeData, setCountNotIncludeData] = useState<any>(0);

    const [aggColIndexesDefault, setAggColIndexes] = useState<Array<any>>(aggColIndexes.length > 0 ? aggColIndexes : getStringColIndexes(fieldTypes, title))
    const [selectActive, setSelectActive] = useState<string>(trans('Select aggregation', language));
    const [selectDataset, setSelectDataset] = useState<any>({});
    const [aggUpdate, setAggUpdate] = useState<boolean>(true);
    const arrStringFunc = getFuncIndexes(aggFunctions, 'string');

    const [metricColIndexes, setMetricColIndexes] = useState<Array<any>>(metricColIndexesDefault.length > 0 ? metricColIndexesDefault : []);
    const [selectMetricActive, setSelectMetricActive] = useState<string>(trans('Select metric', language));
    const [selectMetricDataset, setSelectMetricDataset] = useState<any>({});

    const [noScroll, setNoScroll] = useState<boolean>(true);

    useEffect(()=> {
        const tempSettings:any = {
            agg: aggColIndexes.length > 0 ? aggColIndexes : getStringColIndexes(fieldTypes, title),
            metrics: metricColIndexesDefault.length > 0 ? metricColIndexesDefault : []
        }
        if(aggColIndexes.length > 0) {
            if(arrStringFunc.length > 0) {
                tempSettings.agg = aggColIndexesDefault.concat(arrStringFunc).sort((a, b) => a - b);
            } else {
                tempSettings.agg = aggColIndexes;
            }
        }
        if(navigator && navigator.platform.includes('Win')) {
            setNoScroll(false)
        }

        if(settings) {
            tempSettings.agg = settings.agg?settings.agg:[];
            tempSettings.metrics = settings.metrics?settings.metrics:[];
        } else if(onChangeSettings) {
            onChangeSettings(tempSettings);
        }
        setAggColIndexes(tempSettings.agg);
        setMetricColIndexes(tempSettings.metrics);
        setDefaultSetting(tempSettings);

    }, [])
    const onChangeSelect = (response:any) => {
        try {
            setAggUpdate(true);
            const tempIndexes:any = []
            const tempTitles:any = []
            Object.keys(response).map((index:any)=> {
                if(response[index].checked) {
                    tempIndexes.push(+index);
                    tempTitles.push(response[index].name)
                }
            })
            if(tempTitles.length === 0)
                setSelectActive(trans('Select aggregation', language));
            else
                setSelectActive(tempTitles.join(', '));
            setAggColIndexes([...tempIndexes].sort((a, b)=> a-b));
            if(onChangeSettings)
                onChangeSettings({...defaultSetting, metrics: metricColIndexes, agg: [...tempIndexes].sort((a, b)=> a-b)});
            // setAggUpdate(false);
        } catch (e) {
            console.log('Error - ', e);
        }
    }
    const onChangeMetricSelect = (response:any) => {
        try {
            setAggUpdate(true);
            const tempIndexes:any = []
            const tempTitles:any = []
            Object.keys(response).map((index:any)=> {
                if(response[index].checked) {
                    tempIndexes.push(+index);
                    tempTitles.push(response[index].name)
                }
            })

            if(tempTitles.length === 0)
                setSelectMetricActive(trans('Select metric', language));
            else
                setSelectMetricActive(tempTitles.join(', '));
            setMetricColIndexes([...tempIndexes].sort((a, b)=> a-b));
            if(onChangeSettings)
                onChangeSettings({...defaultSetting, agg: aggColIndexesDefault, metrics: [...tempIndexes].sort((a, b)=> a-b)});
            // setAggUpdate(false);
        } catch (e) {
            console.log('Error - ', e);
        }
    }
    useEffect(()=> {
        if(aggChoice.length > 0 && columnPreferences.length > 0) {
            try {
                setAggUpdate(true);
                const dataset:any = {};
                const datasetMetrics:any = {};
                aggChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    if(index !== -1)
                        dataset[index] = columnPreferences[index].title;
                })
                setSelectDataset(dataset);
                const tempTitlesStretches:string[] = [];
                if(stretch && stretch.length > 0) {
                    stretch.map((item)=> {
                        if(item.list) {
                            for(let i = 0;i < item.list.length; i++) {
                                tempTitlesStretches.push(item.title?item.title:'');
                            }
                        }
                    })
                }
                metricChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    if(index !== -1) {
                        datasetMetrics[index] = columnPreferences[index].title;
                        if(tempTitlesStretches[index]) {
                            datasetMetrics[index] += ` (${trans(tempTitlesStretches[index], language)})`;
                        }
                    }
                })
                setSelectMetricDataset(datasetMetrics);
                if(Object.values(dataset).length > 0 && aggColIndexesDefault.length) {
                    const temp:Array<string> = [];
                    aggColIndexesDefault.map((index)=> {
                        temp.push(columnPreferences[+index].title)
                    })
                    setSelectActive(temp.join(', '))
                } else {
                    // const index = title.indexOf(aggChoice[0]);
                    // if(index !== -1 && columnPreferences[index]) {
                        // setSelectActive(columnPreferences[index].title);
                        // setAggColIndexes([index])
                    // }
                }
            } catch (e) {
                console.log('Error - ', e);
            }
            setAggUpdate(false);
        }else if(metricChoice.length > 0 && columnPreferences.length > 0) {
            try {
                setAggUpdate(true);
                const datasetMetrics:any = {};
                const tempTitlesStretches:string[] = [];
                if(stretch && stretch.length > 0) {
                    stretch.map((item)=> {
                        if(item.list) {
                            for(let i = 0;i < item.list.length; i++) {
                                tempTitlesStretches.push(item.title?item.title:'');
                            }
                        }
                    })
                }
                metricChoice.map((item:any)=> {
                    const index = title.indexOf(item);
                    if(index !== -1) {
                        datasetMetrics[index] = columnPreferences[index].title;
                        if(tempTitlesStretches[index]) {
                            datasetMetrics[index] += ` (${trans(tempTitlesStretches[index], language)})`;
                        }
                    }
                })
                setSelectMetricDataset(datasetMetrics);
            } catch (e) {
                console.log('Error - ', e);
            }
            setAggUpdate(false);
        }
    }, [])

    useEffect(()=> {
        if(typeof fieldTypes === 'object' && fieldTypes.length > 0 && fieldTypes.indexOf("date") > -1) {
            if(data.length>0 && dateGrouping && columnPreferences.length > 0) {
                try {
                    setDataWrapper([]);
                    setDataCopy([]);
                    let result;
                    if(offAgg) {
                        result = [
                            JSON.parse(JSON.stringify(data)),
                            Array(100).fill(1),
                            {data: [], count: 0}
                        ]
                    } else if(dateGrouping === 'day' || serverGroup) {
                      result = aggregateData(JSON.parse(JSON.stringify(data)), columnPreferences, aggColIndexesDefault);
                    } else
                        result = groupByIndex(JSON.parse(JSON.stringify(data)), dateGrouping, columnPreferences, aggColIndexesDefault);
                    setItemCountPercent(result[1])
                    setItemCountPercentCopy(result[1])
                    setDataWrapper(result[0]);
                    setDataCopy(result[0]);

                    setDataTotal(getTotalValues(result[0], columnPreferences, result[1]));
                    // setDataTotal(result[2].data);
                    setCountTotal(result[2].count);
                    if(Object.values(selectDataset).length > 0 && !offAgg) {
                        const temp:Array<string> = [];
                        aggColIndexesDefault.map((index)=> {
                            temp.push(columnPreferences[+index].title)
                        })
                        if(temp.length === 0)
                            setSelectActive(trans('Select aggregation', language));
                        else
                            setSelectActive(temp.join(', '));
                    }
                    setDataReady(true);
                    setAggUpdate(false);
                    return;
                } catch (e) {
                    console.log('Error - ', e);
                    setDataWrapper([]);
                    setDataCopy([]);
                    setAggUpdate(false);
                    return;
                }
            } else {
                setDataCopy([]);
            }
        }
        setAggUpdate(false);
    },[data, dateGrouping, aggColIndexesDefault])
    useEffect(()=> {
        if(fieldTypes.indexOf("date") === -1) {
            if (columnPreferences.length > 0 && data.length>0 && typeof fieldTypes === 'object' && fieldTypes.length > 0) {
                try {
                    let result;
                    if(offAgg) {
                        result = [
                            JSON.parse(JSON.stringify(data)),
                            Array(100).fill(1),
                            {data: [], count: 0}
                        ]
                    } else {
                        result = aggregateData(JSON.parse(JSON.stringify(data)), columnPreferences, aggColIndexesDefault);
                    }
                    setItemCountPercent(result[1])
                    setItemCountPercentCopy(result[1])
                    setDataWrapper(result[0]);
                    setDataCopy(result[0]);
                    // setDataTotal(result[2].data);
                    setDataTotal(getTotalValues(result[0], columnPreferences, result[1]));
                    setCountTotal(result[2].count);
                    if(Object.values(selectDataset).length > 0 && !offAgg) {
                        const temp:Array<string> = [];
                        aggColIndexesDefault.map((index)=> {
                            temp.push(columnPreferences[+index].title)
                        })
                        if(temp.length === 0)
                            setSelectActive(trans('Select aggregation', language));
                        else
                            setSelectActive(temp.join(', '));
                    }
                    setDataReady(true);
                } catch (e) {
                    console.log('Error - ', e);
                    setDataWrapper([]);
                    setDataCopy([]);
                }
            } else {
                setDataCopy([]);
            }
        }
        setAggUpdate(false);
    }, [data, columnPreferences, aggColIndexesDefault])


    const [limitChannelCustom, setLimitChannelCustom] = useState(defaultLimit);

    const per_page_max = (!sortFront && pageCount) ? pageCount : dataWrapper ? getCountPage(dataWrapper.length, limitChannelCustom) : 1;

    const [currentPage, setCurrentPage] = useState(1);
    const pageItems = [];
    for(let i = 1; i <= per_page_max; i++) {
        pageItems.push(i);
    }

    const [activeSort, setActiveSort] = useState<DefaultObject<'default' | 'ascending' | 'descending'>>(defaultSort);
    const [activeSearch, setActiveSearch] = useState<DefaultObject<any>>(defaultSearch);
    const [activeFilter, setActiveFilter] = useState<DefaultObject<any>>(defaultFilter);
    const [activePin, setActivePin] = useState<DefaultObject<any>>({});
    const [defaultSetting, setDefaultSetting] = useState<any>(settings);

    const onChangeFilter = (search:any={}, filter:any={}, sort:any={}) => {
        onChangeActiveSearch(search);
        onChangeActiveFilter(filter);
        onChangeActiveSort(sort);
        setAggUpdate(true);
        setCurrentPage(1);
        setActiveSearch(search);

        const {
            sumNotInclude,
            countNotInclude,
            tempCountPercent,
            tempData,
            tempTotal,
            tempFilter
        } = changeFilter({
            search,
            filter,
            sort,
            dataCopy: dataCopy,
            itemCountPercentCopy: itemCountPercentCopy,
            columnPreferences,
            fieldTypes: fieldTypes
        })

        setActiveFilter(tempFilter);

        setSumNotIncludeData(sumNotInclude);
        setCountNotIncludeData(countNotInclude);

        setActiveSort(sort);
        // setActiveIndex(tempActiveSortIndex);

        setItemCountPercent(tempCountPercent)
        setDataWrapper(tempData);

        setDataTotal(tempTotal);

        setAggUpdate(false);
    }

    useEffect(()=>{
        if(dataCopy.length > 0 && dataReady) {
            onChangeFilter(activeSearch, activeFilter, activeSort);
        } else setDataWrapper([]);
    }, [dataCopy])

    const [flagDownloadModal, setFlagDownloadModal] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagDownloadModal(false)
    }, [selectRef]);
    const [defaultWidthCol, setDefaultWidthCol] = useState<number>(0);
    const [setting, setSetting] = useState<any>({})
    useEffect(()=> {
        if(dataWrapper.length > 0) {
            setDefaultWidthCol(getMinLengthTable({
                columnPreferences, data: dataWrapper, metricChoice, aggColIndexes: aggColIndexesDefault, metricColIndexes
            }));
        }
    }, [dataWrapper])

    const [cur, setCur] = useState<any>(null);

    const handleMouseUp = (e:any) => {
        if(cur) {
            setCur(null);
        }
    }

    const [targetPrice, setTargetPrice] = useState<number>(getMaxTargetPrice(reduxEvents))

    useEffect(()=> {
        if(targetEvents && typeof targetEvents.event_target_price === 'number') {
            setTargetPrice(targetEvents.event_target_price)
        } else if(reduxEvents && reduxEvents.length > 0) {
            setTargetPrice(getMaxTargetPrice(reduxEvents));
        }
    }, [reduxEvents, targetEvents])

    useEffect(() => {
        if(Object.keys(setting).length > 0 && Object.keys(activePin).length > 1) {
            const temp = handlePin({activePin, setting});
            if(temp)
                setActivePin(temp);
        }
    }, [cur, setting]);

    const [flagOptionsModal, setFlagOptionsModal] = useState(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(() => {
        setFlagOptionsModal(false)
    }, [optionsRef]);

    return(
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div className="table-pagination"
             onMouseMove={(e)=>handleMouseMove(e, cur, setting, defaultWidthCol, setSetting)}
             onMouseUp={handleMouseUp}
             onMouseLeave={handleMouseUp}
        >
            {name &&
                <div className={'table-pagination__name'}>
                    {!disableName && <span className='table-pagination__name_text'>{name}</span>}
                    {tabs && activeTab && <Select
                        className='table-pagination__tabs'
                        selectActive={tabs[activeTab]}
                        dataset={tabs}
                        onChangeActive={onChangeTab}
                        widthAuto={true}
                        color={'transparent'}
                    />}
                    {!offAgg && tooltip && <Tooltip text={tooltip} className={`bottom ${posTooltip ? posTooltip : ''}`}/>}
                    {subDescription && <span className='table-pagination__subtitle'>&nbsp;({subDescription})</span> }
                    {!offAgg && dataWrapper.length > 0 && aggChoice.length > 0 && Object.values(selectDataset).length > 0 &&
                        <div className={'table-pagination__agg'}>
                            <Select
                                selectActive={selectActive}
                                dataset={selectDataset}
                                onChangeActive={onChangeSelect}
                                color={'dark'}
                                widthAuto={true}
                                checkbox={true}
                                listActive={aggColIndexesDefault.map((item)=>String(item))}
                            />
                        </div>
                    }
                    {!offAgg && columnPreferences.length>0 && dataWrapper.length > 0 &&<div className={'table-pagination-download'} ref={selectRef}>
                        {metricChoice.length > 0 && Object.values(selectMetricDataset).length > 0 &&
                            <div className={'table-pagination__metrics'}>
                                <Select
                                    icon={settingIcon}
                                    onlyIcon={true}
                                    selectActive={selectMetricActive}
                                    dataset={selectMetricDataset}
                                    onChangeActive={onChangeMetricSelect}
                                    color={'dark'}
                                    widthAuto={true}
                                    checkbox={true}
                                    listActive={metricColIndexes.map((item)=>String(item))}
                                />
                            </div>
                        }


                        <div className='table-pagination-options' ref={optionsRef}>
                            <Btn
                                className='table-pagination-options__btn'
                                color={'dark'}
                                onClick={()=> {
                                      setFlagOptionsModal(!flagOptionsModal);
                                }}
                                isIconBtn={true}
                                icon={{
                                    Component: DotsIcon,
                                    width: 15,
                                    height: 15
                                }}
                            />
                            {flagOptionsModal && <div className="table-pagination-options-popup">
                                {
                                    onOpenFullScreen && !isFullScreen &&
                                        <Btn
                                            className='table-pagination-options__btn'
                                            color={'dark'}
                                            onClick={()=>onOpenFullScreen()}
                                            isIconBtn={true}
                                            icon={{
                                                Component: FullScreenIcon,
                                                width: 15,
                                                height: 15
                                            }}
                                        />
                                }
                                {
                                    isFullScreen && onCloseFullScreen &&
                                        <Btn
                                            className='table-pagination-options__btn'
                                            color={'dark'}
                                            onClick={()=>onCloseFullScreen()}
                                            isIconBtn={true}
                                            icon={{
                                                Component: FullScreenExitIcon,
                                                width: 15,
                                                height: 15
                                            }}
                                        />
                                }
                                {dataWrapper && <div>
                                    <Btn
                                        color={'dark'}
                                        className={'table-pagination-options__btn'}
                                        isIconBtn={true}
                                        icon={{
                                            Component: DownloadIcon,
                                            width: 16,
                                            height: 16
                                        }}
                                        onClick={()=>downloadReport({
                                            type: 'xlsx',
                                            name,
                                            dataWrapper,
                                            setAggUpdate,
                                            dateGrouping,
                                            itemCountPercent,
                                            titles: title,
                                            aggFunctions,
                                            columnPreferences,
                                            fieldTypes,
                                            currency,
                                            metricChoice,
                                            aggColIndexesDefault,
                                            metricColIndexes
                                        })}
                                    />
                                </div>}
                            </div>}
                        </div>
                    </div>}
                </div>
            }
            <div className={`table-pagination__table ${noScroll && 'no-scrollbar'}`} style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ? {minHeight:'250px'} : {}}>
                <div className="table-pagination__head-block" style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ?{minWidth: `${getMinLengthTable({columnPreferences, data: dataWrapper, metricChoice, aggColIndexes: aggColIndexesDefault, metricColIndexes})}px`}:{}}>
                    <table>
                        <thead>
                            { stretch && stretch.length > 0 && (metricChoice.length===0 || metricColIndexes.length>0) && <tr>
                                {
                                    stretch.map((item:any, index:number)=> {
                                        let colSpan = item.list && item.list.length;
                                        if(metricChoice.length && item.list) {
                                            let temp = 0;
                                            item.list.map((itemList:string, key:number)=> {
                                                const i = columnPreferences.findIndex((el:any) => el.name === itemList);
                                                if(aggColIndexesDefault.includes(i) || metricColIndexes.includes(i)) temp++;
                                            })
                                            colSpan = temp;
                                        }
                                        if(metricChoice.length && colSpan === 0) {
                                            if(item.title) return '';
                                            if(item.list && item.list.length === 1) {
                                                return '';
                                            }
                                        }
                                        return <TablePaginationHeadStretchItem
                                            key={index}
                                            title={trans(item.title, language)}
                                            index={index}
                                            data={dataCopy}
                                            colSpan={colSpan}
                                        />
                                    })
                                }
                            </tr>}
                            <tr>
                                {dataCopy.length > 0 && columnPreferences.map((item:any, index:number)=> ((metricChoice.length) ? (aggColIndexesDefault.includes(index) || metricColIndexes.includes(index)) : true) && (
                                    <TablePaginationHeadItem
                                        key={index}
                                        title={item.title}
                                        index={index}
                                        name={item.name}
                                        fieldType={item.type}
                                        lastElementWithType={index === columnPreferences.map((itemCol:any, indexCol:number)=> dataWrapper[0] && dataWrapper[0][indexCol] !=='8|}$$~' && itemCol.type).lastIndexOf(item.type)}
                                        // fieldTypes={fieldTypes}
                                        activeFilter={activeFilter}
                                        setActiveFilter={setActiveFilter}
                                        activeSearch={activeSearch}
                                        setActiveSearch={setActiveSearch}
                                        onFilter={onChangeFilter}
                                        activePin={activePin}
                                        setActivePin={(index:number)=> {
                                            const temp = onPin({index, activePin, setting});
                                            if(temp) setActivePin(temp);
                                        }}
                                        // valueSearch={valueSearch}
                                        activeSort={activeSort}
                                        // activeIndex={activeIndex}
                                        // onChangeSorting={(type:'ascending' | 'descending' | 'default'='default')=>onChangeSort(index, type)}
                                        data={dataCopy}
                                        // dataWrapper={dataWrapper}
                                        // setDataWrapper={setDataWrapper}
                                        offFilter={offFilter}
                                        setCur={setCur}
                                        // itemCount={itemCountPercent}
                                        // setItemCount={setItemCountPercent}
                                    />
                                ))}
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className={`table-pagination__scroll ${(dataWrapper.length === 0 || columnPreferences.length===0) && !aggUpdate ? 'not-found' : ''} ${Object.keys(activePin).length > 0 ? 'no-of' : ''}`} style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ? {minWidth: `${getMinLengthTable({columnPreferences, data: dataWrapper, metricChoice, aggColIndexes: aggColIndexesDefault, metricColIndexes})}px`} : {}}>
                    <table>
                        <tbody>
                            {columnPreferences.length>0 && dataWrapper.length > 0 && dataWrapper.slice((currentPage-1)*limitChannelCustom, currentPage*limitChannelCustom).map((item:any, index:number)=> (
                              <tr key={index}>
                                {item.map((value:string, key:number)=>(
                                    value !== '8|}$$~' && ((metricChoice.length) ? (aggColIndexesDefault.includes(key) || metricColIndexes.includes(key)) : true) && typeof columnPreferences === 'object' && columnPreferences[key] &&
                                    <td key={key}
                                        className={`table-pagination-row__item ${columnPreferences[key].type === 'date' ? 'date-value' : ''} ${columnPreferences[key] && columnPreferences[key].type === 'string' ? 'string-value' : ''} ${/Стоимость целевого действия/i.test(columnPreferences[key].title) ? getColorValue(+value, targetPrice) : ''} ${index === columnPreferences.map((itemCol:any, indexCol:number)=> dataWrapper[0] && dataWrapper[0][indexCol] !=='8|}$$~' && itemCol.type).lastIndexOf(item.type)?'last':''} ${activePin[key] ? 'sticky': ''}`}
                                        style={activePin[key] && activePin[key].left?{left: `${activePin[key].left}px`}: {}}
                                    >
                                        <DashboardOutputValue
                                            columnPreferences={columnPreferences}
                                            itemIndex={key}
                                            value={value}
                                            rowIndex={index}
                                            itemCountPercent={itemCountPercent}
                                            currentPage={currentPage}
                                            limitChannelCustom={limitChannelCustom}
                                            currency={currency}
                                            language={language}
                                        />
                                    </td>
                                ))}
                              </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
                {
                    !hideTotal && dataTotal.length > 0 && dataWrapper.length > 1 &&
                    <div className={`table-pagination__total`} style={columnPreferences.length>0 && dataWrapper && dataWrapper.length > 0 ? {minWidth: `${getMinLengthTable({columnPreferences, data: dataWrapper, metricChoice, aggColIndexes: aggColIndexesDefault, metricColIndexes})}px`} : {}}>
                        <table>
                            <tbody>
                                  <tr>
                                    {dataTotal.map((value:string, key:number)=>(
                                        value !== '8|}$$~' && ((metricChoice.length) ? (aggColIndexesDefault.includes(key) || metricColIndexes.includes(key)) : true) && typeof columnPreferences === 'object' && columnPreferences[key] &&
                                        <td key={key}
                                            className={`table-pagination-row__item ${columnPreferences[key].type === 'date' ? 'date-value' : ''} ${columnPreferences[key] && columnPreferences[key].type === 'string' ? 'string-value' : ''} ${key === columnPreferences.map((itemCol:any, indexCol:number)=> dataWrapper[0] && dataWrapper[0][indexCol] !=='8|}$$~' && itemCol.type).lastIndexOf(columnPreferences[key].type)?'last':''} ${activePin[key] ? 'sticky': ''}`}
                                            style={activePin[key] && activePin[key].left?{left: `${activePin[key].left}px`}: {}}
                                        >
                                            <div className="table-pagination-row__text">
                                                {formattingValues(value, columnPreferences[key].type, currency, false, language)}
                                            </div>
                                        </td>
                                    ))}
                                  </tr>
                            </tbody>
                        </table>
                    </div>
                }
                { (aggUpdate && !dataReady) &&
                    <div className="table-pagination__loader">
                        <Loader inBlock={true} isNotTransparent={true}/>
                    </div>
                }
                {
                    (dataWrapper.length === 0 || columnPreferences.length===0) && !aggUpdate ? (Object.keys(activeSearch).length === 0 && Object.keys(activeFilter).length === 0 ?
                    <Empty className={'absolute'} title={trans('Nothing found', language)} btnCallback={onRepeat} /> :
                    <Empty className={'absolute'} title={trans('Nothing found', language)} btnCallback={()=>{
                        setActiveFilter({})
                        setActiveSearch({})
                        setActiveSort({})
                        onChangeFilter()
                    }} btnText={'Clear filters'} />) :
                    <></>
                }
            </div>

            { (loader) &&
                <Loader inBlock={true} isNotTransparent={true}/>
            }



            {
              data.length > 0 && per_page_max > 1 && columnPreferences.length>0 &&
                <Pagination
                    sortFront={sortFront}
                    onChangeLimitChannel={onChangeLimitChannel}
                    limitChannelCustom={limitChannelCustom}
                    setLimitChannelCustom={setLimitChannelCustom}
                    per_page_max={per_page_max}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    bigData={isBigData}
                />
            }
        </div>
    )
}

export default TablePagination;
