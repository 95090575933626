import {DefaultObject} from "../../interfaces/common.d";

export const getMinLengthTable = ({
    columnPreferences=[],
    data=[],
    metricChoice=[],
    aggColIndexes=[],
    metricColIndexes=[]
}: {
    columnPreferences: Array<any>,
    data: Array<Array<any>>,
    metricChoice: Array<string>,
    aggColIndexes: Array<number>,
    metricColIndexes: Array<number>
}) => {
    const temp = [];
    if (columnPreferences.length > 0 && data.length > 0) {
        data[0].map((value: string, key: number) => {
            if (value !== '8|}$$~' && ((metricChoice.length) ? (aggColIndexes.includes(key) || metricColIndexes.includes(key)) : true) && typeof columnPreferences === 'object' && columnPreferences[key]) {
                temp.push(value);
            }
        })
    }
    return 150 * temp.length;
}


export const onPin = ({
    index,
    activePin={},
    setting,
    minWidth=150
}:{
    index:number,
    activePin:DefaultObject<DefaultObject<number>>,
    setting: DefaultObject<number>,
    minWidth?: number
}) => {
    const temp = {...activePin};
    if (temp[index]) {
        delete temp[index];
        if (Object.keys(temp).length > 0) {
            const first_key = Object.keys(temp)[0];
            temp[first_key] = {
                left: 0
            }
        }
        return temp;
    }
    if (Object.keys(temp).length > 1) return;
    if (Object.keys(temp).length > 0) {
        const first_key = Object.keys(temp)[0];
        if (+first_key < index) {
            temp[index] = {
                left: setting[first_key] ? setting[first_key] : minWidth
            }
        } else {
            temp[index] = {
                left: 0
            }
            temp[first_key] = {
                left: setting[index] ? setting[index] : minWidth
            }
        }
    } else {
        temp[index] = {
            left: 0
        }
    }

    return temp;
}


export const handlePin = ({
    activePin,
    setting
}: {
    activePin:DefaultObject<DefaultObject<number>>,
    setting: DefaultObject<number>
}) => {
    if (Object.keys(setting).length > 0 && Object.keys(activePin).length > 1) {
        try {
            const temp = {...activePin};
            const key_a = Object.keys(temp)[0];
            const key_b = Object.keys(temp)[1];
            if (key_a < key_b && setting[key_a]) {
                temp[key_b] = {
                    left: setting[key_a] ? setting[key_a] : 150
                }
            } else {
                temp[key_a] = {
                    left: setting[key_b] ? setting[key_b] : 150
                }
            }
            return temp;
        } catch (e) {
            console.warn(e);
        }
    }
}