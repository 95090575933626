import {FC, useEffect, useRef, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../../redux/hooks";
import useOnScreen from "../../../../../../hooks/useOnScreen";

import {getCustomDashboards, getDashboards, getLimitsCustomDashboards} from "../../../../../../actions/project";
import {openPopup} from "../../../../../../redux/slices/popup";
import {PopupType} from "../../../../../../data";

import Loader from "../../../../../../components/Loader";

import './style.scss';
import {trans} from "../../../../../../_locales";
import {currentUserLang} from "../../../../../../redux/slices/user";
import TablePagination from "../../../../../../components/Tables/TablePagination";
import TableServer from "../../../../../../components/Tables/TableServer";
import {QueueServer, translit} from "../../../../../../utils";
import {DefaultObject} from "../../../../../../interfaces/common.d";
import {closeModal, ModalName, openModal} from "../../../../../../redux/slices/modals";
import {ICustomReport} from "../../../../../../components/ReportsUpdate/interfaces.d";

export interface IDashboardsTable {
    name:string,
    disableName?:boolean,
    defaultName?:string,
    subDescription?:string,
    projectId:number,
    periodStart:string,
    periodEnd:string,
    selectDate?:string,
    model?:string,
    categorize?:string,
    window?:number,
    filter?:any,
    targetEvents?:any,
    serverGroup?:boolean,
    noActual?:boolean,
    setActual?:any,
    queue?:QueueServer,
    weight?:number,
    offAgg?:boolean,
    defaultAgg?:Array<string>,
    defaultFilter?:Array<any>,
    defaultSort?:Array<any>,
    defaultLimit?:number,
    defaultOffset?:number,
    metricInclude?:boolean,
    defaultMetric?:Array<any>,
    defaultRowsOnPage?:number,
    tabs?:DefaultObject<string>,
    fullscreen?:boolean
    isBigData?:boolean
    defaultResponse?: DefaultObject<any>
    defaultActiveSearch?: DefaultObject<any>
    defaultActiveFilter?: DefaultObject<any>
    defaultActiveSort?: DefaultObject<'ascending' | 'descending' | 'default'>
    isFirstLoading?:boolean
    hideTotal?:boolean,
    isTest?:boolean,
    isDefaultRequest?:boolean,
    keyForSession?:string,
    type?: string,
    customFilters?: Array<any>,
    customTrgFilters?: Array<any>,
    ecomFilters?: Array<any>,
    interactionType?: ICustomReport["interaction_type"],
    trgPeriodStart?: string,
    trgPeriodEnd?: string,
    posTooltip?: 'left' | 'right'
}

const TIMEOUT_RETRY_LIMIT = 300_000;

const DashboardsTable:FC<IDashboardsTable> = ({
    name,
    defaultName,
    subDescription,
    projectId,
    periodStart,
    periodEnd,
    selectDate,
    model,
    window,
    categorize,
    filter,
    targetEvents,
    serverGroup,
    noActual=false,
    setActual,
    queue,
    weight=serverGroup?15:10,
    offAgg=false,
    defaultAgg=[],
    defaultFilter=[],
    defaultSort=[],
    defaultLimit=10000,
    defaultOffset=0,
    metricInclude=false,
    defaultMetric=[],
    defaultRowsOnPage,
    tabs,
    disableName=false,
    fullscreen=false,
    isBigData=false,
    defaultResponse,
    isFirstLoading=false,
    defaultActiveSearch={},
    defaultActiveFilter={},
    defaultActiveSort={},
    hideTotal=false,
    isTest=false,
    isDefaultRequest=true,
    keyForSession='settings_table_temp',
    type,
    customFilters=[],
    customTrgFilters,
    ecomFilters,
    interactionType,
    trgPeriodStart,
    trgPeriodEnd,
    posTooltip
}) => {
    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);

    const [data, setData] = useState<any>();
    const [tableName, setTableName] = useState<string>(name);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [no, setNo] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement | null>(null)
    const isVisible = useOnScreen(ref);
    const [firstLoading, setFirstLoading] = useState<boolean>(isFirstLoading);

    const [defaultActiveSearchValue, setDefaultActiveSearchValue] = useState(defaultActiveSearch);
    const [defaultActiveFilterValue, setDefaultActiveFilterValue] = useState(defaultActiveFilter);
    const [defaultActiveSortValue, setDefaultActiveSortValue] = useState(defaultActiveSort);

    const [tableSort, setTableSort] = useState<Array<any>>(defaultSort);
    const [tableAgg, setTableAgg] = useState<Array<string>>(defaultAgg);
    const [tableFilter, setTableFilter] = useState<Array<any>>(customFilters);
    const [activeSorting, setActiveSorting] = useState<any>({});
    const [activeFiltering, setActiveFiltering] = useState<any>({});
    const [limit, setLimit] = useState<number>(defaultLimit);
    const [offset, setOffset] = useState<number>(defaultOffset);
    const [defaultRequest, setDefaultRequest] = useState<boolean>(isDefaultRequest);
    const [isRetry, setIsRetry] = useState<boolean>(false);
    const [timeoutRetry, setTimeoutRetry] = useState<number>(5000);
    const [sumTimeRetry, setSumTimeRetry] = useState<number>(5000);
    const [currentResponse, setCurrentResponse] = useState<DefaultObject<any>>(defaultResponse?defaultResponse:{});
    const [settings, setSettings] = useState<DefaultObject<any>>({});
    const [limits, setLimits] = useState<string>('');

    useEffect(() => {
        const temp = sessionStorage.getItem(keyForSession);
        if(temp) {
            const parse = JSON.parse(temp);
            setSettings(parse);
        }
        dispatch(getLimitsCustomDashboards({projectId: projectId})).then((r:any)=>{
            if(r.payload) {
                setLimits(r.payload);
            }
        })
    }, []);
    const onChangeSettings = (data:DefaultObject<any> = {}) => {
        const session = sessionStorage.getItem(keyForSession);
        const temp = session ? JSON.parse(session) : JSON.parse("{}");
        if(tabs && activeTab) {
            temp[activeTab] = data;
        } else {
            temp[name] = data;
        }
        setSettings(temp);
        sessionStorage.setItem(keyForSession, JSON.stringify(temp));
    }

    const [activeTab, setActiveTab] = useState<string>(tabs ? Object.keys(tabs)[0] : '')
    const onChangeTab = (res:DefaultObject<string>) => {
        for(const key in res) {
            if(tabs && tabs[key]) {
                setActiveTab(key);
                setTableName(key);
                setFirstLoading(false);
            }
        }
    }

    const on = (response:any) => {
        if (response || response==0) {
            setData(response);
            if(response.data) {
                if(response.data.settings.sort) {
                    const temp:any = {};
                    response.data.settings.sort.map((item:any) => {
                        temp[item.name] = item.value;
                    })
                    setActiveSorting(temp)
                }
                if(response.data.settings.filter_custom) {
                    const temp:any = {};
                    response.data.settings.filter_custom.map((item:any) => {
                        temp[item.name] = {
                            condition: item.condition,
                            value: item.value
                        };
                    })
                    setActiveFiltering(temp)
                }
            }

            setShowLoader(false);
        }
    }

    const serverCb = (r:any) => {
        if (r.payload && r.payload[tableName]) {
            setCurrentResponse(r);
            if (typeof r.payload[tableName].error === 'string') {
                dispatch(openPopup({type: PopupType.ERROR, description: r.payload[tableName].error}));
                setNo(true);
            } else {
                try {
                    on(r.payload[tableName]);
                    setNo(false);
                } catch (e) {
                    on(0);
                    setNo(true);
                    console.log('Error', e);
                }
            }
        } else {
            on(0);
            setNo(true);
        }
    }

    const retryRequest = () => {
        setTimeout(() => {
            setFirstLoading(false);
            setSumTimeRetry(sumTimeRetry + timeoutRetry);
        }, timeoutRetry);
    }

    const getData = (arg:any) => {
        if(queue) {
            queue.enqueue(()=> {
                return dispatch(getDashboards(arg)).then((r) => {
                    if(r.payload && r.payload === 'timeout of 30000ms exceeded' || r.payload === 'The request is already being sent') {
                        if(sumTimeRetry <= TIMEOUT_RETRY_LIMIT) {
                            setIsRetry(true);
                            retryRequest();
                            return;
                        }
                    }
                    serverCb(r);
                }).catch((e) => {
                    console.log('Error', e);
                    on(0);
                    setNo(true);
                    setShowLoader(false);
                })
            }, weight);
        } else {
            dispatch(getDashboards(arg)).then((r) => {
                if(r.payload && r.payload === 'timeout of 30000ms exceeded' || r.payload === 'The request is already being sent') {
                    if (sumTimeRetry <= TIMEOUT_RETRY_LIMIT) {
                        setIsRetry(true);
                        retryRequest();
                        return;
                    }
                }
                serverCb(r);
            }).catch((e) => {
                console.log('Error', e);
                on(0);
                setNo(true);
                setShowLoader(false);
            })
        }
    }

    const getExtendData = (arg:any) => {
        if(queue) {
            queue.enqueue(()=> {
                return dispatch(getCustomDashboards(arg)).then((r) => {
                    serverCb(r);
                }).catch((e) => {
                    console.log('Error', e);
                    on(0);
                    setNo(true);
                    setShowLoader(false);
                })
            }, weight);
        } else {
            dispatch(getCustomDashboards(arg)).then((r) => {
                serverCb(r);
            }).catch((e) => {
                console.log('Error', e);
                on(0);
                setNo(true);
                setShowLoader(false);
            })
        }
    }

    useEffect(()=> {
        if(defaultResponse) {
            serverCb(defaultResponse);
        } else setFirstLoading(false);
    }, [])
    useEffect(() => {
        if (projectId && periodStart && periodEnd) {
            setFirstLoading(false);
        }
    },[projectId, periodStart, periodEnd, model, window, filter, targetEvents, dispatch])
    useEffect(()=> {
        if(projectId && periodStart && periodEnd && serverGroup) {
            setFirstLoading(false);
        }
    }, [categorize])
    useEffect(()=> {
        if(projectId && periodStart && periodEnd && (isVisible || isTest) && !firstLoading && !serverGroup) {
            setShowLoader(true);
            on(0);
            setNo(false);
            const arg:any = {
                projectId: Number(projectId),
                periodStart: periodStart,
                periodEnd: periodEnd,
                names: [
                    tableName,
                ],
                type,
                model: model,
                window: window,
                filter: filter,
                categorize: 'day',
                targetEvents: targetEvents,
                retry: isRetry
            }
            getData(arg);
            setFirstLoading(true);
        }
    },[tableName, projectId, periodStart, periodEnd, model, window, categorize, filter, targetEvents, isVisible,firstLoading])
    useEffect(()=> {
        if(projectId && periodStart && periodEnd && (isVisible || isTest) && !firstLoading && serverGroup && !noActual) {
            setShowLoader(true);
            on(0);
            setNo(false);
            const arg:any = {
                projectId: Number(projectId),
                periodStart: periodStart,
                periodEnd: periodEnd,
                names: [
                    tableName
                ],
                type,
                model: model,
                window: window,
                filter: filter,
                categorize: categorize,
                targetEvents: targetEvents,
                aggregation: tableAgg,
                sort: tableSort,
                filter_fields: tableFilter,
                limit: limit,
                offset: offset,
                defaultReq: defaultRequest,
                metrics_include: metricInclude,
                metrics: defaultMetric,
                filter_trg: customTrgFilters,
                filter_ecom: ecomFilters,
                interaction_type: interactionType,
                trgPeriodStart,
                trgPeriodEnd
            }

            getExtendData(arg);
            setFirstLoading(true);
        }
    },[tableName, projectId, periodStart, periodEnd, model, window, categorize, filter, targetEvents, isVisible,firstLoading, tableAgg, tableSort, tableFilter])

    const downloadTable = (format:'csv'|'xlsx', sort:any=[], filterFields:any=[], aggregation:string[] = []) => {
        if (projectId && periodStart && periodEnd && serverGroup) {
            setShowLoader(true);
            setNo(false);
            let query = `interval_from=${periodStart}&interval_to=${periodEnd}&name_id=${tableName}${data && data.item_title ? `&name_file=${translit(defaultName ? defaultName : data.item_title)}` : ''}`;
            if(model) {
                query += `&model=${model}`
            }
            if(window) {
                query += `&window=${window}`
            }
            if(categorize) {
                query += `&scale=${categorize}`
            }
            if (aggregation && aggregation.length > 0) {
                query += `&aggregation=${aggregation.join(',')}`;
            }
            if (sort && sort.length > 0) {
                let prepareSort = '';
                sort.map((item:any, index:number)=> {
                    if(index !== 0) prepareSort += ';';
                    prepareSort += `name=${item.name},value=${item.value}`
                })
                query += `&sort=${prepareSort}`;
            }
            if (filter && filter.length > 0) {
                let prepareFilter = '';
                filter.map((item:any, index:number)=> {
                    if(index !== 0) prepareFilter += ';';
                    const prepareValue = Array.isArray(item.value) ? item.value.join('^^^') : item.value
                    prepareFilter += `name=${item.name}|value=${prepareValue}|condition=${item.condition}`
                })
                query += `&filters=${prepareFilter}`
            }
            if(filterFields) {
                let prepare = '';
                filterFields.map((item:any, index:number)=> {
                    if(index !== 0) prepare += ';';
                    prepare += `name=${item.name},value=${item.value},condition=${item.condition}`
                })
                query += `&filter_fields=${prepare}`;
            }
            if(defaultMetric) {
                query += `&metrics=${defaultMetric.join(',')}`;
            }
            if(targetEvents.event_name) {
                const temp = [];
                if(targetEvents.event_name) temp.push(`event_name=${targetEvents.event_name}`);
                if(targetEvents.event_type) temp.push(`event_type=${targetEvents.event_type}`);
                if(targetEvents.event_category) temp.push(`event_category=${targetEvents.event_category}`);
                query += `&target_events=${temp.join(',')}`;
            }
            if(format) {
                query += `&type_file=${format}`
            }
            if(type) {
                query += `&dash_type=${type}`
            }
            if(customTrgFilters) {
                let prepareFilter = '';
                customTrgFilters.map((item:any, index:number)=> {
                    if(index !== 0) prepareFilter += ';';
                    prepareFilter += `name=${item.name},value=${item.value},condition=${item.condition}`
                })
                query += `&filter_trg=${prepareFilter}`
            }
            if(ecomFilters) {
                let prepareFilter = '';
                ecomFilters.map((item:any, index:number)=> {
                    if(index !== 0) prepareFilter += ';';
                    const prepareValue = Array.isArray(item.value) ? item.value.join('^^^') : item.value
                    prepareFilter += `name=${item.name}|value=${prepareValue}|condition=${item.condition}`
                })
                query += `&filter_ecom=${prepareFilter}`
            }
            if(interactionType) {
                query += `&interaction_type=${interactionType.join(',')}`;
            }
            if(trgPeriodStart && trgPeriodEnd) {
                query += `&trg_event_dt_from=${trgPeriodStart}&trg_event_dt_to=${trgPeriodEnd}`
            }
            if(limits) {
                query += `&lf=${limits}`;
            }
            const a = document.createElement('a');
            const domain = document.location.origin;
            // const domain = 'http://127.0.0.1:8000';
            a.setAttribute('href', `${domain}/api/projects/${projectId}/custom-reports/table/download/?${query}`);
            a.click();
            setShowLoader(false);
        }
    }
    return(
        <div ref={ref} className='dashboard-table'>
            {data ? (serverGroup ?
                    <TableServer
                        data={data.data.dataset}
                        title={data.data.column ? data.data.column.map((item:any)=>item.name) : []}
                        subDescription={subDescription}
                        columnPreferences={data.data.column ? data.data.column : []}
                        fieldTypes={data.data.column ? data.data.column.map((item:any)=>item.type) : []}
                        loader={showLoader}
                        dateGrouping={categorize}
                        name={defaultName ? defaultName : data.item_title}
                        tooltip={data.description}
                        aggColIndexes={
                            offAgg && data.data.settings && tableAgg ? tableAgg.map((item:any) => data.data.column.findIndex((el:any) => el.name === item)) :
                            data.data.settings && data.data.settings.agg && data.data.settings.agg.default ? data.data.settings.agg.default.map((item:any) => data.data.column.findIndex((el:any) => el.name === item)): []
                        }
                        aggFunctions={data.data.column ? data.data.column.map((item:any)=>item.agg_function) : []}
                        aggChoice={data.data.settings && data.data.settings.agg ? data.data.settings.agg.choice : []}
                        aggChoiceTitles={data.data.settings && data.data.settings.agg ? data.data.settings.agg.titles : []}
                        onRepeat={(sort:any=[], filter:any=[], aggregation:string[] = [], offsetValue:number=0, limitValue:number=limit)=> {
                            setDefaultRequest(false);
                            setTableFilter(filter);
                            setTableSort(sort);
                            setTableAgg(aggregation);
                            setLimit(limitValue);
                            setOffset(offsetValue);
                            setFirstLoading(false);
                            setNo(false);
                            setShowLoader(true);
                            setActual(false);
                        }}
                        activeSorting={activeSorting}
                        activeFiltering={activeFiltering}
                        limit={limit}
                        offset={offset}
                        onDownload={downloadTable}
                        noActual={noActual}
                        offAgg={offAgg}
                        defaultRowsOnPage={defaultRowsOnPage}
                    />
                    :
                    <TablePagination
                          data={data.data.dataset}
                          title={data.data.column ? data.data.column.map((item:any)=>item.name) : []}
                          subDescription={subDescription}
                          columnPreferences={data.data.column ? data.data.column : []}
                          fieldTypes={data.data.column ? data.data.column.map((item:any)=>item.type) : []}
                          sortFront={true}
                          loader={showLoader}
                          dateGrouping={categorize}
                          name={defaultName ? defaultName : data.item_title}
                          tooltip={data.description}
                          posTooltip={posTooltip}
                          aggColIndexes={data.data.settings && data.data.settings.agg && data.data.settings.agg.default ? data.data.settings.agg.default.map((item:any) => data.data.column.findIndex((el:any) => el.name === item)): []}
                          aggFunctions={data.data.column ? data.data.column.map((item:any)=>item.agg_function) : []}
                          aggChoice={data.data.settings && data.data.settings.agg ? data.data.settings.agg.choice : []}
                          metricColIndexesDefault={data.data.settings && data.data.settings.metrics && data.data.settings.metrics.default ? data.data.settings.metrics.default.map((item:any) => data.data.column.findIndex((el:any) => el.name === item)): []}
                          metricChoice={data.data.settings && data.data.settings.metrics ? data.data.settings.metrics.choice : []}
                          targetEvents={targetEvents}
                          stretch={data.data.settings && data.data.settings.stretch}
                          tabs={tabs}
                          disableName={disableName}
                          activeTab={activeTab}
                          onChangeTab={onChangeTab}
                          isBigData={isBigData}
                          defaultLimit={isBigData?30:10}
                          defaultFilter={fullscreen ? defaultActiveFilterValue: {}}
                          defaultSearch={fullscreen ? defaultActiveSearchValue: {}}
                          defaultSort={fullscreen ? defaultActiveSortValue: {}}
                          onChangeActiveFilter={setDefaultActiveFilterValue}
                          onChangeActiveSearch={setDefaultActiveSearchValue}
                          onChangeActiveSort={setDefaultActiveSortValue}
                          hideTotal={hideTotal}
                          isFullScreen={fullscreen}
                          onCloseFullScreen={fullscreen ? () => {dispatch(closeModal(ModalName.DASHBOARD_FULLSCREEN))} : undefined}
                          settings={activeTab? settings[activeTab] : settings[name]}
                          onChangeSettings={onChangeSettings}
                          onOpenFullScreen={!fullscreen ? ()=> {
                              dispatch(openModal({
                                    name: ModalName.DASHBOARD_FULLSCREEN,
                                    data: {
                                        title: defaultName ? defaultName : data.item_title,
                                        response: currentResponse,
                                        type: 'table',
                                        projectId: projectId,
                                        data: {
                                            name,
                                            defaultName,
                                            subDescription,
                                            projectId,
                                            periodStart,
                                            periodEnd,
                                            selectDate,
                                            model,
                                            window,
                                            categorize,
                                            filter,
                                            targetEvents,
                                            serverGroup,
                                            noActual: noActual? noActual: false,
                                            setActual,
                                            queue,
                                            weight: serverGroup ? 15 : 10,
                                            offAgg: offAgg? offAgg: false,
                                            defaultAgg:defaultAgg?defaultAgg:[],
                                            defaultFilter:defaultFilter?defaultFilter:[],
                                            defaultSort:defaultSort?defaultSort:[],
                                            defaultLimit:defaultLimit?defaultLimit:10000,
                                            defaultOffset:defaultOffset?defaultOffset:0,
                                            metricInclude:metricInclude?metricInclude:false,
                                            defaultMetric:defaultMetric?defaultMetric:[],
                                            defaultRowsOnPage,
                                            tabs,
                                            disableName,
                                            fullscreen: true,
                                            isBigData: true,
                                            defaultResponse: currentResponse,
                                            isFirstLoading: true,
                                            defaultActiveSearch: defaultActiveSearchValue,
                                            defaultActiveFilter: defaultActiveFilterValue,
                                            defaultActiveSort: defaultActiveSortValue,
                                            hideTotal
                                        }
                                    }
                              }))
                          }:undefined}
                      />
                )
               : no ?
                <TablePagination
                    data={[]}
                    title={[]}
                    fieldTypes={[]}
                    sortFront={true}
                    loader={showLoader}
                    name={trans(name, language)}
                    onRepeat={()=> {
                        setFirstLoading(false);
                        setNo(false);
                        setShowLoader(true);
                        if(serverGroup) setActual(false);
                    }}
                    tabs={tabs}
                    disableName={disableName}
                    activeTab={activeTab}
                    onChangeTab={onChangeTab}
                />
                    :
              <div className='dashboard-table-loader'>
                <Loader inBlock={true} isNotTransparentLight={true}/>
              </div>
            }
        </div>
    )
}

export default DashboardsTable;