import {FC, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {eventsData} from "../../../../../redux/slices/events";
import {getIsLoading} from "../../../../../redux/slices/projects";
import {setBreadcrumbs} from "../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../redux/slices/sidebar";

import {deleteEvent, getEvents} from "../../../../../actions/events";

import {ReactComponent as PlusIcon} from '../../../../../assets/icons/icon-plus.svg'
import editIcon from "../../../../../assets/icons/edit.svg";
import removeIcon from "../../../../../assets/icons/icon-close-red.svg";

import {Btn, SearchForm} from "../../../../../ui";

import Loader from "../../../../../components/Loader";
import Empty from "../../../../../components/Empty";
import Pagination from "../../../../../components/ui/Pagination";

import {getCountPage} from "../../../../../components/Tables/TablePagination/functions";

import './style.scss';
import {projectGeneral} from "../../../../../redux/slices/project";
import {currencySign, sendSdk} from "../../../../../utils";
import {trans} from "../../../../../_locales";
import {getProjectGeneral} from "../../../../../actions/project";
import {clearInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";

const EventsList = () => {
  const reduxEvents = useAppSelector(eventsData);
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const isLoading = useAppSelector(getIsLoading);
  const language = useAppSelector(currentUserLang);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const navigate = useNavigate();
  const dispatch = useAppDispatch()
  const { projectId } = useParams();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('events') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('events')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;

  if(permissions && !isView) {
    navigate(`/projects/${projectId}/`)
  }

  useEffect(()=> {
        if(projectId && reduxUserId) {
            sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'events'}})
        }
    }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(clearInstructions());
    dispatch(setBreadcrumbs([
        {
          href: '/projects/',
          title: 'Проект',
        },
        {
          href: `/projects/${projectId}/events/`,
          title: trans('Event targets', language)
        }
      ]))
    setTitle([
      trans('Event target name', language),
      trans('Target cost', language),
      trans('Event type', language),
      trans('Event category', language),
      trans('Event name', language),
      trans('Event value', language),
      // trans('Number of events', language),
      trans('Creation date', language)
    ])
    dispatch(setSidebar(getPages({
        projectId: Number(projectId),
        access: permissions && permissions['can_view'],
        language,
        active: 'events'
    })))
    if(permissions && permissions['can_view'] && !permissions['can_edit'] && !permissions['can_create'] && !permissions['can_delete']) {
      setTitle([
          trans('Event target name', language),
          trans('Target cost', language),
          trans('Event type', language),
          trans('Event category', language),
          trans('Event name', language),
          trans('Event value', language)
      ]);
    }
  }, [dispatch, projectId, reduxUser.roles, permissions]);

  useEffect(() => {
    const header = document.querySelector('header');
    const body = document.querySelector('body');
    const content = document.querySelector('.page-content');
    header && header.classList.remove('hide');
    content && content.classList.remove('stretch');
    body && body.classList.remove('no-scroll');
  }, []);

  const [dataWrapper, setDataWrapper] = useState<Array<any>>([...reduxEvents]);
  const [limitChannelCustom, setLimitChannelCustom] = useState(10);

  useEffect(() => {
    dispatch(getEvents({projectId})).then((r)=> {
        if(r.payload && r.payload.length) {
            setDataWrapper([...r.payload]);
        }
    });
    reduxUser.id && projectId && dispatch(getProjectGeneral({currentUserId: reduxUser.id, projectId: +projectId}));
  }, [dispatch, projectId, reduxUser]);
  useEffect(() => {
      if(reduxEvents.length > 0)
        setDataWrapper([...reduxEvents]);
  }, [reduxEvents])
  const per_page_max = getCountPage(dataWrapper.length, limitChannelCustom);
  const [searchValue, setSearchValue] = useState<string>('');
  const [title, setTitle] = useState<Array<any>>([
      trans('Event target name', language),
      trans('Target cost', language),
      trans('Event type', language),
      trans('Event category', language),
      trans('Event name', language),
      trans('Event value', language),
      // trans('Number of events', language),
      trans('Creation date', language)
  ])
  const titleName = ['event_target_name', 'event_target_price', 'event_type', 'event_category', 'event_name', 'event_value', 'created_at']
  const [currentPage, setCurrentPage] = useState(1);
  const pageItems = [];
  for(let i = 1; i <= per_page_max; i++) {
      pageItems.push(i);
  }

  const onChangeValueSearch= (value:string) => {
    if(value === '') {
        setDataWrapper(reduxEvents);
        setSearchValue('')
        return;
    }
    const regex = new RegExp(value);
    setSearchValue(value);
    setDataWrapper(reduxEvents.filter((item:any) => regex.test(item[titleName[0]])));
  }
  const onChangeSortTableCustom = (event:any, index:number) => {
        const element = event.target.closest('th');
        const tempData = [...dataWrapper];

        setDataWrapper(tempData.sort(function (a, b) {
          if (a[titleName[index]] > b[titleName[index]]) {
            return 1;
          }
          if (a[titleName[index]] < b[titleName[index]]) {
            return -1;
          }
          return 0;
        }));
        if(element.classList.contains('ascending')) {
          const tempData = [...dataWrapper];
          setDataWrapper(tempData.sort(function (a, b) {
              if (a[titleName[index]] < b[titleName[index]]) {
                return 1;
              }
              if (a[titleName[index]] > b[titleName[index]]) {
                return -1;
              }
              return 0;
          }));
          element.className = 'descending';
          return;
        }
        if(element.classList.contains('descending')) {
          element.className = '';
          const tempData = [...dataWrapper];
          setDataWrapper(tempData.sort(function (a, b) {
              if (a[titleName[0]] < b[titleName[0]]) {
                return 1;
              }
              if (a[titleName[0]] > b[titleName[0]]) {
                return -1;
              }
              return 0;
          }));
          return;
        }
        Array.from(element.parentNode.childNodes).map((item:any) => (
            item.className = ''
        ))
        element.className = 'ascending';
    }

  interface IThead {
      title: Array<any>
      onChangeSort: any
  }

  const Thead:FC<IThead> = ({
    title,
    onChangeSort
  }) => {
      return(
        <thead>
            <tr>
              {
                title.map((item:string, index:number)=> (
                    <th key={index} onClick={(event)=>{
                        onChangeSort(event, index);
                    }}>
                      <div className="events-list-table__title">
                        <span>{item}</span>
                          <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.6 1C5.6 0.668629 5.33137 0.4 5 0.4C4.66863 0.4 4.4 0.668629 4.4 1L5.6 1ZM4.57574 11.4243C4.81005 11.6586 5.18995 11.6586 5.42426 11.4243L9.24264 7.60589C9.47696 7.37157 9.47696 6.99167 9.24264 6.75736C9.00833 6.52304 8.62843 6.52304 8.39411 6.75736L5 10.1515L1.60589 6.75736C1.37157 6.52304 0.991674 6.52304 0.757359 6.75736C0.523045 6.99167 0.523045 7.37157 0.757359 7.60589L4.57574 11.4243ZM4.4 1L4.4 11L5.6 11L5.6 1L4.4 1Z" fill="transparent"/>
                          </svg>
                      </div>
                    </th>
                ))
              }
              <th></th>
              <th></th>
            </tr>
        </thead>
      )
  }

  interface ITbody {
      titles?: Array<any>
      projectId?:number | string
      data: Array<any>
      currentPage: number
      limitChannel: number
  }

  const Tbody:FC<ITbody> = ({
      projectId,
      data,
      currentPage,
      limitChannel
  }) => {
      const reduxCurrentProject = useAppSelector(projectGeneral);
      const currency = reduxCurrentProject ? reduxCurrentProject.currency : undefined;
      return(
        <tbody>
            {data.slice((currentPage-1)*limitChannel, currentPage*limitChannel).map((item:any, index:number)=> (
              <tr key={index}>
                <td>{item.event_target_name ? item.event_target_name : '-'}</td>
                <td>{item.event_target_price ? item.event_target_price : '0'} {currencySign(currency)}</td>
                {/*<td>{item.calculate_attribution_models}</td>*/}
                <td>{item.event_type?item.event_type:'-'}</td>
                <td>{item.event_category?item.event_category:'-'}</td>
                <td>{item.event_name}</td>
                <td>{item.event_value ? item.event_value : "0"} {currencySign(currency)}</td>
                <td>{item.created_at && isView ? item.created_at : "-"}</td>
                <td>
                    {(isEdit || item.author_id === reduxUserId) && <button className='events-list-table__btn' onClick={()=>navigate(`/projects/${projectId}/events/edit/${item.id}/`)}>
                        <img src={editIcon} alt=""/>
                    </button>}
                </td>
                <td>
                    {(isDelete || item.author_id === reduxUserId) && <button className='events-list-table__btn' onClick={()=>{
                        dispatch(deleteEvent({projectId:projectId, eventsId: item.id}));
                        sendSdk('event', {
                            'event_type': 'delete',
                            'event_category': 'events',
                            'event_name': 'action',
                            'event_value': '10',
                            'event_params': {
                                'project_id': projectId,
                                'user_id': reduxUserId
                            }
                        });
                    }}>
                        <img src={removeIcon} alt=""/>
                    </button>}
                </td>
              </tr>
            ))
            }
        </tbody>
      )
  }

  return (
      <>
      {(isLoading) &&
        <Loader inBlock={true}/>}
      { reduxEvents.length > 0 ?
        <div className="events-list">
          <div className="events-list__header">
            <SearchForm
              value={searchValue}
              onChangeCb={(value) => {
                onChangeValueSearch(value);
              }}
            />
            {isCreate && <button className={'events-list__add'} onClick={()=>navigate(`/projects/${projectId}/events/create/`)}><span></span></button>}
          </div>
          <div className='events-list-table__wrapper'>
            {dataWrapper && dataWrapper.length > 0 &&
              <table className="events-list-table">
                <Thead title={title} onChangeSort={onChangeSortTableCustom}/>
                <Tbody projectId={projectId} data={dataWrapper} currentPage={currentPage} limitChannel={limitChannelCustom} />
              </table>
            }
          </div>

        { dataWrapper.length === 0 &&
            <Empty title={trans('Nothing found', language)} />
        }

        {
          dataWrapper.length > 0 && per_page_max > 1 &&
          <Pagination
                sortFront={true}
                onChangeLimitChannel={null}
                limitChannelCustom={limitChannelCustom}
                setLimitChannelCustom={setLimitChannelCustom}
                per_page_max={per_page_max}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
          />
        }
        </div>
      : !(isLoading) &&
        <div className="events-list__empty">
            <h2 className="title title--black">
                {trans('Event targets', language)}<br/> {trans('not founds', language)}
            </h2>
            <Btn
              text={trans('Create', language)}
              icon={{
                Component: PlusIcon,
                width: 12,
                height: 12,
              }}
              color='light-blue'
              size='md'
              onClick={()=>navigate(`/projects/${projectId}/events/create/`)}
              disabled={!isCreate}
            />
        </div>
      }
    </>
  );
};


export default EventsList;