import React, {FC, useEffect, useState} from 'react'
import cx from 'classnames'
import { ITable, ITableRow } from './Table.d'
import { ItemStatus } from '../../../interfaces/common.d'
import { useNavigate } from 'react-router-dom'
import { Toggle } from '../../../ui'
import { Popup } from '../../index'
import { numbersFormatWithLetters } from '../../../helpers'
import { ReactComponent as DotsIcon } from '../../../assets/icons/icon-dots.svg'
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/icon-archive.svg'
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-close.svg'
import './Table.scss'
import { ModalName, openModal} from '../../../redux/slices/modals'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  deleteProjectById,
  changeProjectStatus
} from '../../../actions/projects'
import { deleteCampaign, changeCampaignStatus } from '../../../actions/media'
import {currentUser, currentUserLang, userId} from '../../../redux/slices/user'
import { deletePlacement, changePlacementStatus } from '../../../actions/placements'
import {projectGeneral} from "../../../redux/slices/project";
import {currencySign, sendSdk} from "../../../utils";
import {trans} from "../../../_locales";
import settingIcon from '../../../assets/icons/icon-setting.svg'
import {currentCampaign, setCurrentCampaign} from "../../../redux/slices/breadcrumbs";

const TableRow: FC<ITableRow> = ({ className, type, data, allChecked=false, onChoice, chosen={} }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reduxUserId = useAppSelector(userId);
  const reduxUser =  useAppSelector(currentUser);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const reduxCurrentCampaign = useAppSelector(currentCampaign);
  const language = useAppSelector(currentUserLang);
  const currency = reduxCurrentProject ? reduxCurrentProject.currency : undefined;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(()=> {
      setIsChecked(allChecked);
      // onChoice();
  }, [allChecked])

  if (type === 'project') {
    const projectRowClickHandler: React.MouseEventHandler<HTMLTableRowElement> = (evt) => {
      const popup = evt.currentTarget.querySelector('.popup');
      const setting = evt.currentTarget.querySelector('.table-row__setting');
      const checkboxCell = evt.currentTarget.querySelector('.table-row__checkbox');
      const selection = document.getSelection();

      if ((!selection || selection.toString().length === 0) && !(evt.target === popup || popup?.contains(evt.target as Node) || evt.target === checkboxCell || checkboxCell?.contains(evt.target as Node) || (evt.target === setting || setting?.contains(evt.target as Node)))) {
        sendSdk('event', {
            'event_type': 'link',
            'event_category': 'project',
            'event_name': 'campaigns',
            'event_value': '10',
            'event_params': {
                'project_id': data.id,
                'user_id': reduxUserId,
            }
        });
        navigate(`/projects/${data.id}/`);
      }
    };

    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${data.id}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_general') || data.owner_id == reduxUser.id;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_general') || data.owner_id == reduxUser.id;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_general') || data.owner_id == reduxUser.id;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_general') || data.owner_id == reduxUser.id;

    const onDeleteProject = (userId:any, projectId:any) => {
        dispatch(openModal({
          name:ModalName.CONFIRM_DELETE,
          data: {
            text: trans('Confirm the deletion of the project', language),
            onConfirm: () => deleteProject(userId, projectId)
          }
        }))
    }
    const deleteProject = (userId:any, projectId:any) => {
        dispatch(deleteProjectById({userId: userId, projectId: projectId}))
    }

    return (
      <tr className={cx('table-row', 'table-row--pointer', className, isChecked ? 'table-checked' : '')} onClick={projectRowClickHandler}>
        {/*<td className="table-row__checkbox">*/}
          {/*<Toggle*/}
          {/*  name='row-choice'*/}
          {/*  borderMod={true}*/}
          {/*  onChange={(evt)=>setIsChecked(evt.currentTarget.checked)}*/}
          {/*/>*/}
        {/*</td>*/}
        <td className="table-row__name">
          {data.name}
          {/*{isView && !isEdit && !isCreate && !isDelete &&*/}
          {/*  <span className='role'>*/}
          {/*      {trans('View', language)}*/}
          {/*  </span>*/}
          {/*}*/}
        </td>
        <td>
            {trans(data.status, language)}
        </td>
        <td className="table-row__author align-left">
            {(reduxUser.email===data.author_email || (isEdit || isCreate || isDelete)) ? data.author_name : trans('Name hidden', language)}<span>{(reduxUser.email===data.author_email || (isEdit || isCreate || isDelete)) ? data.author_email : trans('Mail hidden', language)}</span>
        </td>
        <td>
          {data.created_at.split('-').map(item => item.length===1 ? '0'+item : item).join('-')}
        </td>
        <td className="table-row__menu">
            <button className='table-row__setting'
                    onClick={()=>{
                        sendSdk('event', {
                            'event_type': 'link',
                            'event_category': 'project',
                            'event_name': 'settings',
                            'event_value': '10',
                            'event_params': {
                                'project_id': data.id,
                                'user_id': reduxUserId,
                            }
                        });
                        navigate(`/projects/${data.id}/settings/`);
                    }}>
                <img src={settingIcon} alt=""/>
            </button>
        </td>
      </tr>
    )
  } else if (type === 'campaign') {
    const placementRowClickHandler: React.MouseEventHandler<HTMLTableRowElement> = (evt) => {
      const popup = evt.currentTarget.querySelector('.popup');
      const checkboxCell = evt.currentTarget.querySelector('.table-row__checkbox');
      const statusCell = evt.currentTarget.querySelector('.table-row__status');
      const selection = document.getSelection();

      if ((!selection || selection.toString().length === 0) && !(evt.target === popup || popup?.contains(evt.target as Node) || evt.target === checkboxCell || checkboxCell?.contains(evt.target as Node) || evt.target === statusCell || statusCell?.contains(evt.target as Node))) {
        sendSdk('event', {
            'event_type': 'link',
            'event_category': 'campaign',
            'event_name': 'creatives',
            'event_value': '10',
            'event_params': {
                'project_id': data.id,
                'user_id': reduxUserId,
            }
        });
        navigate(`/projects/${data.project_id}/campaigns/${data.id}/platforms`);
      }
    };

    const trClassName = cx(
      'table-row',
      'table-row--pointer',
      className,
      {
        'is-delete':  data.status === ItemStatus.DELETED,
        'is-archived':  data.status === ItemStatus.ARCHIVE,
      },
      isChecked ? 'table-checked' : ''
    );

    const statusCellClassName = cx(
      'table-row__status',
      {
        'table-row__status--toggle': data.status === ItemStatus.ACTIVE || data.status === ItemStatus.INACTIVE,
      }
    )

    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${data.project_id}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    if(permissions && !isView) {
        navigate(`/projects/${data.project_id}/`)
    }

    // const impression = data.Placement ? data.Placement.reduce((prev, curr) => prev + curr.stat.impression, 0) : 0;
    // const reach = data.Placement ? data.Placement.reduce((prev, curr) => prev + curr.stat.reach, 0) : 0;
    // const clicks = data.Placement ? data.Placement.reduce((prev, curr) => prev + curr.stat.cLicks, 0) : 0;
    // const spent = data.Placement ? data.Placement.reduce((prev, curr) => prev + curr.stat.spent, 0) : 0;
    // const pv_leads = data.Placement ? data.Placement.reduce((prev, curr) => prev + curr.stat.pv_leads, 0) : 0;
    // const pv_cpa = data.Placement ? data.Placement.reduce((prev, curr) => prev + curr.stat.pv_cpa, 0) : 0;

    const onDeleteCampaign = (userId:any, projectId:any, campaignId:any) => {
        dispatch(openModal({
          name:ModalName.CONFIRM_DELETE,
          data: {
            text: trans('Confirm the deletion of the campaign', language),
            onConfirm: () => removeCampaign(userId, projectId, campaignId)
          }
        }))
    }
    const removeCampaign = (userId:any, projectId:any, campaignId:any) => {
        dispatch(deleteCampaign({
            userId: userId,
            projectId: projectId,
            campaignId: campaignId
        })).then((r)=> {
            if (r.payload === 'Cannot delete campaign as it has dependant records. Confirmation is needed to delete campaign with its dependencies') {
                setTimeout(()=> {
                    dispatch(openModal({
                        name: ModalName.CONFIRM_DELETE,
                        data: {
                            text: trans('Are you sure you want to delete a campaign with its creatives?', language),
                            onConfirm: () => {
                                dispatch(deleteCampaign({
                                    userId: userId,
                                    projectId: projectId,
                                    campaignId: campaignId,
                                    confirm: true
                                }))
                            }
                        }
                    }))
                }, 400)
            }
        })
        sendSdk('event', {
            'event_type': 'delete',
            'event_category': 'campaign',
            'event_name': 'action',
            'event_value': '10',
            'event_params': {
                'project_id': data.id,
                'user_id': reduxUserId,
                'campaign_id': campaignId
            }
        });
    }

    const onChangeStatusCampaign = (checked:boolean, userId:number, projectId:number, campaignId:number) => {
        dispatch(openModal({
          name:ModalName.CONFIRM_DELETE,
          data: {
            text: trans('Confirm the changing status of the campaign', language),
            title: trans('Confirmation of the change', language),
            textConfirm: 'Confirm',
            onConfirm: () => changeStatusCampaign(checked, userId, projectId, campaignId)
          }
        }))
    }
    const changeStatusCampaign = (checked:boolean, userId:number, projectId:number, campaignId:number) => {
        if (checked) {
          dispatch(changeCampaignStatus({
            status: ItemStatus.ACTIVE,
            userId: userId,
            projectId: projectId,
            campaignId: campaignId
          }))
        } else {
          dispatch(changeCampaignStatus({
            status: ItemStatus.INACTIVE,
            userId: userId,
            projectId: projectId,
            campaignId: campaignId,
          }))
        }
    }

    return (
      <tr className={trClassName} onClick={placementRowClickHandler}>
        <td className="table-row__checkbox">
          <Toggle
            name='row-choice'
            borderMod={true}
            value={`${data.id}`}
            onChange={(evt)=>{
                setIsChecked(evt.currentTarget.checked);
                const temp:any = chosen;
                temp[data.id] = evt.currentTarget.checked;
                onChoice(temp);
            }}
            isChecked={typeof chosen[data.id] === 'boolean' ? chosen[data.id] : isChecked}
          />
        </td>
        <td className={statusCellClassName}>
          {(data.status === ItemStatus.ACTIVE || data.status === ItemStatus.INACTIVE) && (
            <Toggle
              name='row-name'
              isSlider={true}
              isChecked={data.status === ItemStatus.ACTIVE}
              onChange={(evt) => {
                if((isEdit || isCreate || isDelete)) {
                    onChangeStatusCampaign(evt.currentTarget.checked, reduxUserId, data.project_id, data.id);
                }
              }}
            />
          )}
          {data.status === ItemStatus.ARCHIVE && (
            <ArchiveIcon width={23} height={23} />
          )}
          {data.status === ItemStatus.DELETED && (
            <CloseIcon width={15} height={15} />
          )}
        </td>
        <td className="table-row__name">
          {data.name}
        </td>
        <td>
          {numbersFormatWithLetters(data.stats.impression)}
        </td>
        <td>
          {numbersFormatWithLetters(data.stats.clicks)}
        </td>
        <td>
          {numbersFormatWithLetters(data.stats.reach)}
        </td>
        <td>
          <span className="table-row__unit">{currencySign(currency)}&nbsp;</span>
          <span>{numbersFormatWithLetters(data.stats.spent)}</span>
        </td>
        <td>
          {/* <span className="table-row__unit">₽&nbsp;</span> */}
          <span>{numbersFormatWithLetters(data.stats.leads)}</span>
        </td>
        <td>
          {/* <span className="table-row__unit">₽&nbsp;</span> */}
          <span className="table-row__unit">{currencySign(currency)}&nbsp;</span>
          <span>{new Intl.NumberFormat('ru-RU').format(data.stats.postview_cpa).replace(',', '.')}</span>
        </td>
        <td>
          {data.created_at}
        </td>
        <td className="table-row__menu">
            {isEdit && isCreate && isDelete ?
             <Popup
                btn={{
                  icon: {
                    Component: DotsIcon,
                    width: 6,
                    height: 22
                  },
                  isIconBtn: true,
                  size: 'lg'
                }}
                dropdownList={{
                  items: [
                    {
                      text: trans('Create based on', language),
                      onClick: () => {
                          dispatch(openModal({
                              name: ModalName.CAMPAIGN_BASED,
                              data: {
                                  userId: reduxUserId,
                                  status: data.status,
                                  projectId: data.project_id,
                                  campaignId: data.id,
                                  campaign: data
                              }
                          }))
                          sendSdk('event', {
                              'event_type': 'open',
                              'event_category': 'campaign',
                              'event_name': 'create_based',
                              'event_value': '10',
                              'event_params': {
                                  'project_id': data.project_id,
                                  'user_id': reduxUserId,
                                  'campaign_id': data.id
                              }
                          });
                      }
                    },
                    {
                      text: trans('Add a creative pixel', language),
                      onClick: () => {
                          dispatch(openModal({
                              name: ModalName.NEW_PLATFORM,
                              data: {
                                  userId: reduxUserId,
                                  projectId: data.project_id ? +data.project_id : -1,
                                  campaignId: data.id ? +data.id : -1,
                                  sourceId: (reduxCurrentCampaign ? reduxCurrentCampaign.source_id : -1)
                              }
                          }))
                          sendSdk('event', {
                              'event_type': 'open',
                              'event_category': 'creative',
                              'event_name': 'create',
                              'event_value': '10',
                              'event_params': {
                                  'project_id': data.project_id,
                                  'user_id': reduxUserId,
                                  'campaign_id': data.id
                              }
                          });
                      }
                    },
                    {
                      text: trans('Edit', language),
                      onClick: () => {
                          dispatch(openModal({
                              name: ModalName.CAMPAIGN_EDITING,
                              data: {
                                  userId: reduxUserId,
                                  status: data.status,
                                  projectId: data.project_id,
                                  campaignId: data.id,
                                  campaign: data
                              }
                          }))
                          sendSdk('event', {
                              'event_type': 'open',
                              'event_category': 'campaign',
                              'event_name': 'edit',
                              'event_value': '10',
                              'event_params': {
                                  'project_id': data.project_id,
                                  'user_id': reduxUserId,
                                  'campaign_id': data.id
                              }
                          });
                      }
                    },
                    // {
                    //   text: trans('Hold', language),
                    //   onClick: () => dispatch(changeCampaignStatus({
                    //     status: ItemStatus.INACTIVE,
                    //     userId: reduxUserId,
                    //     projectId: data.project_id,
                    //     campaignId: data.id
                    //   }))
                    // },
                    {
                      text: data.status === ItemStatus.ARCHIVE ? trans('Activate', language) : trans('Archive', language),
                      onClick: () => {
                        if (data.status === ItemStatus.ARCHIVE) {
                          dispatch(changeCampaignStatus({
                            status: ItemStatus.ACTIVE,
                            userId: reduxUserId,
                            projectId: data.project_id,
                            campaignId: data.id
                          }))
                        } else {
                          dispatch(changeCampaignStatus({
                            status: ItemStatus.ARCHIVE,
                            userId: reduxUserId,
                            projectId: data.project_id,
                            campaignId: data.id
                          }))
                        }
                      }
                    },
                    {
                      text: trans('Delete', language),
                      onClick: () => onDeleteCampaign(reduxUserId, data.project_id, data.id)
                    }
                  ]
                }}
              />
            : isEdit && isCreate &&
            <Popup
                btn={{
                  icon: {
                    Component: DotsIcon,
                    width: 6,
                    height: 22
                  },
                  isIconBtn: true,
                  size: 'lg'
                }}
                dropdownList={{
                  items: [
                    {
                      text: trans('Create based on', language),
                      onClick: () => {
                          dispatch(openModal({
                              name: ModalName.CAMPAIGN_BASED,
                              data: {
                                  userId: reduxUserId,
                                  status: data.status,
                                  projectId: data.project_id,
                                  campaignId: data.id,
                                  campaign: data
                              }
                          }))
                          sendSdk('event', {
                              'event_type': 'open',
                              'event_category': 'campaign',
                              'event_name': 'create_based',
                              'event_value': '10',
                              'event_params': {
                                  'project_id': data.project_id,
                                  'user_id': reduxUserId,
                                  'campaign_id': data.id
                              }
                          });
                      }
                    },
                    {
                      text: trans('Add a creative pixel', language),
                      onClick: () => {
                          dispatch(openModal({
                              name: ModalName.NEW_PLATFORM,
                              data: {
                                  userId: reduxUserId,
                                  projectId: data.project_id ? +data.project_id : -1,
                                  campaignId: data.id ? +data.id : -1,
                                  sourceId: (reduxCurrentCampaign ? reduxCurrentCampaign.source_id : -1)
                              }
                          }))
                          sendSdk('event', {
                              'event_type': 'open',
                              'event_category': 'creative',
                              'event_name': 'create',
                              'event_value': '10',
                              'event_params': {
                                  'project_id': data.project_id,
                                  'user_id': reduxUserId,
                                  'campaign_id': data.id
                              }
                          });
                      }
                    },
                    {
                      text: trans('Edit', language),
                      onClick: () => {
                          dispatch(openModal({
                              name: ModalName.CAMPAIGN_EDITING,
                              data: {
                                  userId: reduxUserId,
                                  status: data.status,
                                  projectId: data.project_id,
                                  campaignId: data.id,
                                  campaign: data
                              }
                          }))
                          sendSdk('event', {
                              'event_type': 'open',
                              'event_category': 'campaign',
                              'event_name': 'edit',
                              'event_value': '10',
                              'event_params': {
                                  'project_id': data.project_id,
                                  'user_id': reduxUserId,
                                  'campaign_id': data.id
                              }
                          });
                      }
                    },
                    // {
                    //   text: trans('Hold', language),
                    //   onClick: () => dispatch(changeCampaignStatus({
                    //     status: ItemStatus.INACTIVE,
                    //     userId: reduxUserId,
                    //     projectId: data.project_id,
                    //     campaignId: data.id
                    //   }))
                    // },
                    {
                      text: data.status === ItemStatus.ARCHIVE ? trans('Activate', language) : trans('Archive', language),
                      onClick: () => {
                        if (data.status === ItemStatus.ARCHIVE) {
                          dispatch(changeCampaignStatus({
                            status: ItemStatus.ACTIVE,
                            userId: reduxUserId,
                            projectId: data.project_id,
                            campaignId: data.id
                          }))
                        } else {
                          dispatch(changeCampaignStatus({
                            status: ItemStatus.ARCHIVE,
                            userId: reduxUserId,
                            projectId: data.project_id,
                            campaignId: data.id
                          }))
                        }
                      }
                    }
                  ]
                }}
              />
            }

        </td>
      </tr>
    );
  } else if (type === 'placement') {
    const trClassName = cx(
      'table-row',
      className,
      {
        'is-delete':  data.status === ItemStatus.DELETED,
        'is-archived':  data.status === ItemStatus.ARCHIVE,
      },
      isChecked ? 'table-checked' : ''
    );

    const statusCellClassName = cx(
      'table-row__status',
      {
        'table-row__status--toggle': data.status === ItemStatus.ACTIVE || data.status === ItemStatus.INACTIVE,
      }
    );

    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${data.project_id}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;

    if(permissions && !isView) {
        navigate(`/projects/${data.project_id}/campaigns/`)
    }

    const onDeletePlacement = (userId:any, projectId:any, campaignId:any, placementId:any) => {
        dispatch(openModal({
          name:ModalName.CONFIRM_DELETE,
          data: {
            text: trans('Confirm the deletion of the placement', language),
            onConfirm: () => removePlacement(userId, projectId, campaignId, placementId)
          }
        }))
    }
    const removePlacement = (userId:any, projectId:any, campaignId:any, placementId:any) => {
        dispatch(deletePlacement({
            userId: userId,
            projectId: projectId,
            campaignId: campaignId,
            placementId: placementId
        }))
        sendSdk('event', {
            'event_type': 'delete',
            'event_category': 'creative',
            'event_name': 'action',
            'event_value': '10',
            'event_params': {
                'project_id': projectId,
                'user_id': userId,
                'campaign_id': campaignId,
                'placement_id': placementId
            }
        });
    }

    return (
      <tr className={trClassName}>
        <td className="table-row__checkbox">
          <Toggle
            name='row-choice'
            borderMod={true}
            value={`${data.id}`}
            onChange={(evt)=>{
                setIsChecked(evt.currentTarget.checked)
                const temp:any = chosen;
                temp[data.id] = evt.currentTarget.checked;
                onChoice(temp)
            }}
            isChecked={typeof chosen[data.id] === 'boolean' ? chosen[data.id] : isChecked}
          />
        </td>
        <td className={statusCellClassName}>
          {(data.status === ItemStatus.ACTIVE || data.status === ItemStatus.INACTIVE) && (
            <Toggle
              name='row-name'
              isSlider={true}
              isChecked={data.status === ItemStatus.ACTIVE}
              onChange={(evt) => {
                if((isEdit || isCreate || isDelete)) {
                    if (evt.currentTarget.checked) {
                      dispatch(changePlacementStatus({
                        status: ItemStatus.ACTIVE,
                        userId: reduxUserId,
                        projectId: data.project_id,
                        campaignId: data.campaign_id,
                        placementId: data.id
                      }))
                    } else {
                      dispatch(changePlacementStatus({
                        status: ItemStatus.INACTIVE,
                        userId: reduxUserId,
                        projectId: data.project_id,
                        campaignId: data.campaign_id,
                        placementId: data.id
                      }))
                    }
                }
              }}
            />
          )}
          {data.status === ItemStatus.ARCHIVE && (
            <ArchiveIcon width={23} height={23} />
          )}
          {data.status === ItemStatus.DELETED && (
            <CloseIcon width={15} height={15} />
          )}
        </td>
        <td className="table-row__name">
          {data.name}
        </td>
        <td>
          {numbersFormatWithLetters(data.stats.impression)}
        </td>
        <td>
          {numbersFormatWithLetters(data.stats.clicks)}
        </td>
        <td>
          {numbersFormatWithLetters(data.stats.reach)}
        </td>
        <td>
          <span className="table-row__unit">{currencySign(currency)}&nbsp;</span>
          <span>{numbersFormatWithLetters(data.stats.spent)}</span>
        </td>
        <td>
          {/* <span className="table-row__unit">₽&nbsp;</span> */}
          <span>{numbersFormatWithLetters(data.stats.leads)}</span>
        </td>
        <td>
          {/* <span className="table-row__unit">₽&nbsp;</span> */}
          <span className="table-row__unit">{currencySign(currency)}&nbsp;</span>
          <span>{new Intl.NumberFormat('ru-RU').format(data.stats.postview_cpa).replace(',', '.')}</span>
        </td>
        <td>
          {data.created_at}
        </td>
        <td className="table-row__menu">
            {(isEdit && isCreate && isDelete) ?
                <Popup
                    btn={{
                      icon: {
                        Component: DotsIcon,
                        width: 6,
                        height: 22
                      },
                      isIconBtn: true,
                      size: 'lg'
                    }}
                    dropdownList={{
                      items: [
                        {
                          text: trans('Create based on', language),
                          onClick: () => {
                              dispatch(openModal({
                                  name: ModalName.PLATFORM_BASED,
                                  data: {
                                      userId: reduxUserId,
                                      projectId: data.project_id,
                                      campaignId: data.campaign_id,
                                      placementId: data.id,
                                      sourceId: data.source_id,
                                      fields: data
                                  }
                              }))
                              sendSdk('event', {
                                  'event_type': 'open',
                                  'event_category': 'creative',
                                  'event_name': 'create_based',
                                  'event_value': '10',
                                  'event_params': {
                                      'project_id': data.project_id,
                                      'user_id': reduxUserId,
                                      'campaign_id': data.campaign_id,
                                      'placement_id': data.id
                                  }
                              });
                          }
                        },
                        {
                          text: trans('Edit', language),
                          onClick: () => {
                              dispatch(openModal({
                                  name: ModalName.PLATFORM_EDITING,
                                  data: {
                                      userId: reduxUserId,
                                      projectId: data.project_id,
                                      campaignId: data.campaign_id,
                                      placementId: data.id,
                                      sourceId: data.source_id,
                                      fields: data
                                  }
                              }))
                              sendSdk('event', {
                                  'event_type': 'open',
                                  'event_category': 'creative',
                                  'event_name': 'edit',
                                  'event_value': '10',
                                  'event_params': {
                                      'project_id': data.project_id,
                                      'user_id': reduxUserId,
                                      'campaign_id': data.campaign_id,
                                      'placement_id': data.id
                                  }
                              });
                          }
                        },
                        {
                          text: trans('Hold', language),
                          onClick: () => dispatch(changePlacementStatus({
                            status: ItemStatus.INACTIVE,
                            userId: reduxUserId,
                            projectId: data.project_id,
                            campaignId: data.campaign_id,
                            placementId: data.id
                          }))
                        },
                        {
                          text: data.status === ItemStatus.ARCHIVE ? trans('Activate', language) : trans('Archive', language),
                          onClick: () => {
                            if (data.status === ItemStatus.ARCHIVE) {
                              dispatch(changePlacementStatus({
                                status: ItemStatus.ACTIVE,
                                userId: reduxUserId,
                                projectId: data.project_id,
                                campaignId: data.campaign_id,
                                placementId: data.id
                              }))
                            } else {
                              dispatch(changePlacementStatus({
                                status: ItemStatus.ARCHIVE,
                                userId: reduxUserId,
                                projectId: data.project_id,
                                campaignId: data.campaign_id,
                                placementId: data.id
                              }))
                            }
                          }
                        },
                        {
                          text: trans('Delete', language),
                          onClick: () => onDeletePlacement(reduxUserId, data.project_id, data.campaign_id, data.id)
                        },
                      ]
                    }}
                  />
            : (isEdit) &&
                  <Popup
                    btn={{
                      icon: {
                        Component: DotsIcon,
                        width: 6,
                        height: 22
                      },
                      isIconBtn: true,
                      size: 'lg'
                    }}
                    dropdownList={{
                      items: [
                        {
                          text: trans('Edit', language),
                          onClick: () => {
                              dispatch(openModal({
                                  name: ModalName.PLATFORM_EDITING,
                                  data: {
                                      userId: reduxUserId,
                                      projectId: data.project_id,
                                      campaignId: data.campaign_id,
                                      placementId: data.id,
                                      sourceId: data.source_id,
                                      fields: data
                                  }
                              }))
                              sendSdk('event', {
                                  'event_type': 'open',
                                  'event_category': 'creative',
                                  'event_name': 'edit',
                                  'event_value': '10',
                                  'event_params': {
                                      'project_id': data.project_id,
                                      'user_id': reduxUserId,
                                      'campaign_id': data.campaign_id,
                                      'placement_id': data.id
                                  }
                              });
                          }
                        },
                        {
                          text: trans('Hold', language),
                          onClick: () => dispatch(changePlacementStatus({
                            status: ItemStatus.INACTIVE,
                            userId: reduxUserId,
                            projectId: data.project_id,
                            campaignId: data.campaign_id,
                            placementId: data.id
                          }))
                        },
                        {
                          text: data.status === ItemStatus.ARCHIVE ? trans('Activate', language) : trans('Archive', language),
                          onClick: () => {
                            if (data.status === ItemStatus.ARCHIVE) {
                              dispatch(changePlacementStatus({
                                status: ItemStatus.ACTIVE,
                                userId: reduxUserId,
                                projectId: data.project_id,
                                campaignId: data.campaign_id,
                                placementId: data.id
                              }))
                            } else {
                              dispatch(changePlacementStatus({
                                status: ItemStatus.ARCHIVE,
                                userId: reduxUserId,
                                projectId: data.project_id,
                                campaignId: data.campaign_id,
                                placementId: data.id
                              }))
                            }
                          }
                        }
                      ]
                    }}
                  />
            }

        </td>
      </tr>
    )
  }

  return <></>
}

const Table: FC<ITable> = ({ className, type, rowsData, allChecked, onChoice, chosen }) => {
  const tableClassName = cx(
    'table',
    {
      'table--project': type === 'project',
    },
    'scrolled',
    className
  );

  return (
    <div className={tableClassName}>
      {type === 'project' && (
        <table>
          <tbody>
            {rowsData && rowsData.length > 0 && rowsData.map((item) => {
              return <TableRow key={item.id} type={type} data={item} onChoice={()=>null}/>
            })}
          </tbody>
        </table>
      )}
      {type === 'campaign' && (
        <table>
          <tbody>
            {rowsData && rowsData.length > 0 && rowsData.map((item) => {
              return <TableRow key={item.id} type={type} data={item} allChecked={allChecked} onChoice={onChoice} chosen={chosen}/>
            })}
          </tbody>
        </table>
      )}
      {type === 'placement' && (
        <table>
          <tbody>
            {rowsData && rowsData.length > 0 && rowsData.map((item) => {
              return <TableRow key={item.id} type={type} data={item} allChecked={allChecked} onChoice={onChoice} chosen={chosen}/>
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default Table
