/*
  Function groupByDate replaces the date with the desired format
  @param { data } - Array<Array<any>>
  |   a list which group
  @param { dateGrouping } - string (day || month || quarter || year)
  |   Grouping value
  @param { fieldTypes } - string[]
  |   list of all column types
> Return { Array<Array> } a list of grouped elements
*/
import {getDefaultValue, isNumber} from "../../../utils";

export const groupByDate:(arr:Array<Array<any>>, dateGrouping:string, fieldTypes:string[]) => (any[]) = (arr, dateGrouping, fieldTypes) => {
    const indexDate = fieldTypes.indexOf("date");
    if(indexDate > -1) {
        if(dateGrouping === 'week') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2]);
                        const year = date.getFullYear();
                        const month = date.getMonth()<9 ? `0${date.getMonth()+1}` : date.getMonth()+1;
                        const day = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

                        item[indexDate] = `${year}-${month}-${day < 10 ? `0${Math.abs(+day)}` : day}`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
        if(dateGrouping === 'month') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2]);
                        const year = date.getFullYear();
                        const month = date.getMonth()<9 ? `0${date.getMonth()+1}` : date.getMonth()+1 ;
                        item[indexDate] = `${year}-${month}`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
        if(dateGrouping === 'quarter') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2])
                        item[indexDate] = `${date.getFullYear()}/${Math.ceil((date.getMonth()+1)/3)}`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
        if(dateGrouping === 'year') {
            try {
                const temp = [...arr];
                temp.map((item:any)=> {
                    if(/^[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/u.test(item[indexDate])) {
                        const dateArr = item[indexDate].split('-');
                        const date = new Date(+dateArr[0], dateArr[1] - 1, +dateArr[2])
                        item[indexDate] = `${date.getFullYear()}`;
                    }
                })
                return temp;
            } catch (e) {
                console.log('Error - ', e);
            }
        }
    }
    return [...arr];
}

/*
  Function getElementByIndex searches for list items by index
  @param { arrays } - Array<Array<any>>
  |   a list with lists of where to get the elements from
  @param { index } - number
  |   index of elements which need to find
  @param { groupValue } - any
  |   Grouping value
  @param { groupIndex } - number
  |   Grouping index
> Return { [] } a list of elements
*/
export const getElementByIndex = (arrays:Array<Array<any>>, index:number, groupValue?:any,groupIndex?:number) => {
    const result:Array<any> = [];
    if(groupValue) {
        arrays.map((item)=> {
          if ((groupIndex && groupValue === item[groupIndex])) {
              result.push(item[index]);
          }
        })
    } else {
        arrays.map((item)=> {
          result.push(item[index]);
        })
    }
    return result;
}

/*
  Function findDuplicates finds individual values without duplicates
  @param { arrays } - Array<Array<any>>
  |   a list with lists of where to get the elements from
  @param { index } - number
  |   index of elements which need to find
> Return { [] } a list of elements
*/
export const findDuplicates = (arrays:Array<Array<any>>, index:number) => {
    const result = getElementByIndex(arrays,index);
    return result.filter((item, index) => result.indexOf(item) === index)
}

/*
  Function getAvgPercent finds the average value
  @param { arrays } - Array<Array<any>>
  |   a list with lists of where to get the elements from
  @param { index } - number
  |   index of elements which need to find
  @param { value } - any
  |   Grouping value
  @param { groupIndex } - number
  |   Grouping index
> Return { number } a average value
*/
export const getAvgPercent = (arrays:Array<Array<any>>, index:number, value:any, groupIndex:number) => {
    const result = getElementByIndex(arrays,index, value, groupIndex);
    return result.reduce((a, b) => a + b) / result.length;
}

/*
  Function groupByIndex groups an array
  @param { data } - Array<Array<any>>
  |   a list with lists of where to get the elements from
  @param { groupIndex } - number
  |   index of elements which need to group
  @param { dateGrouping } - string (day || month || year)
  |   Grouping value
  @param { types } - Array<string>
  |   list of all column types
> Return { [[]] } a list of elements' list
*/
export const groupByIndex = (data:Array<Array<any>>, dateGrouping:string, columns:Array<any>, aggColName:number[]) => {
    try {
        const arrays:(any)[][] = groupByDate(data, dateGrouping, columns.map((item:any)=>item.type));
        return aggregateData(arrays, columns,aggColName)
    } catch (e) {
        console.log('Error - ', e)
    }
    return [[],[]]
}

export const getCountPage = (dataLength:number, limit:number) => {
    return Math.ceil(dataLength / limit);
}

export const onChangeSortTableCustom = (type:'ascending' | 'descending' | 'default'='default', index:number, data:any, cb:any, types:any) => {
    try {
        const string = types[index].toLowerCase() === 'string' || types[index].toLowerCase() === 'date';
        if(type === 'ascending') {
            const tempData = [...data];
            cb(tempData.sort(function (a, b) {
              if (string ? a[index] > b[index] : +a[index] > +b[index]) {
                return 1;
              }
              if (string ? a[index] < b[index] : +a[index] < +b[index]) {
                return -1;
              }
              return 0;
            }));
        }
        if(type === 'descending') {
          const tempData = [...data];
          cb(tempData.sort(function (a, b) {
              if (string ? a[index] < b[index] : +a[index] < +b[index]) {
                return 1;
              }
              if (string ? a[index] > b[index] : +a[index] > +b[index]) {
                return -1;
              }
              return 0;
          }));
          return;
        }
        if(type ==='default') {
          const tempData = [...data];
          cb(tempData.sort(function (a, b) {
              if (a[0] < b[0]) {
                return 1;
              }
              if (a[0] > b[0]) {
                return -1;
              }
              return 0;
          }));
          return;
        }
    } catch (e) {
        console.log('Error - ', e)
    }
}

export const changeCurrentPage = (
    event:any,
    page:number,
    per_page_max:number,
    setPageValue:any,
    setMiddlePage:any,
    setCurrentPage:any
)=> {
    if(page < 1 || page > per_page_max || isNaN(page)) {
        setPageValue('');
        return;
    }
    if(event.key === 'Enter') {
        Object.values(event.target.parentNode.parentNode.children).map((item:any) => {
            item.classList.remove('active');
            if((page < 4 || page > per_page_max - 2) && item.children.length > 0 && item.children[0].innerText == page) {
                item.classList.add('active');
            }
        })
        if(page > 3 && page < per_page_max - 1) {
            setMiddlePage(page);
            event.target.parentNode.parentNode.children[3].classList.add('active');
        }
        setCurrentPage(page);
        return;
    }
    Object.values(event.target.parentNode.parentNode.children).map((item:any) => (
        item.classList.remove('active')
    ))
    if(event.target.classList.contains('table-pagination-paginator__arrow')) {
        Object.values(event.target.parentNode.children[1].children).map((item:any) => {
            item.classList.remove('active');
            if((page < 4 || page > per_page_max - 2) && item.children.length > 0 && item.children[0].innerText == page) {
                item.classList.add('active');
            }
        })
        if(page > 3 && page < per_page_max - 1) {
            setMiddlePage(page);
            event.target.parentNode.children[1].children[3].classList.add('active');
        }
        setCurrentPage(page);
        return;
    }
    if(page > 3 && page < per_page_max - 1) {
        setMiddlePage(page);
        setCurrentPage(page);
        event.target.parentNode.parentNode.children[3].classList.add('active');
        return;
    }
    event.target.parentNode.classList.add('active');
    setCurrentPage(page);
    if(page !== per_page_max - 1)
        setMiddlePage(4);
}
export const getStringColIndexes = (types:string[], labels:string[]) => {
    const temp:number[] = [];
    types.map((value, index) => {
        if(value === 'string' || value === 'date')
            temp.push(index);
    })
    return temp;
}

export const getFuncIndexes = (funcs?:Array<'skip'|'string'|'max'|'min'|'avg'|'sum'>, search?:string) => {
    const temp:number[] = [];
    funcs && search && funcs.map((value, index) => {
        if(value === search) temp.push(index);
    })
    return temp;
}

const changeCol = ( oldValue:any, newValue:any, func:'skip'|'string'|'max'|'min'|'avg'|'sum'='sum', type:string) => {
    if (func === 'skip' || oldValue === '8|}$$~')
        return '8|}$$~';
    if ((func === 'sum' || func === 'avg') && newValue!== '') {
        if (isNumber(oldValue) && isNumber(oldValue)) {
            const value = +oldValue + +newValue;
            if (type)
                return getDefaultValue(value, type);
            return value;
        }
        return `${oldValue}, ${newValue}`;
    }

    if (func === 'max') {
        if (isNumber(oldValue) && isNumber(oldValue)) {
            if (+oldValue > +newValue) return getDefaultValue(oldValue, type);
            else return getDefaultValue(newValue, type);
        }
        return oldValue > newValue ? oldValue : newValue;
    }
    if (func === 'min') {
        if (isNumber(oldValue) && isNumber(oldValue)) {
            if (+oldValue < +newValue) return getDefaultValue(oldValue, type);
            else return getDefaultValue(newValue, type);
        }
        return oldValue < newValue ? oldValue : newValue;
    }
    if(func === 'string') {
        return newValue;
    }
    return '8|}$$~';
}

export function aggregateData(
    data:(any)[][],
    column:any[],
    aggColIndexes:number[]
) {
    try {
        const resultMap = new Map();
        const extendMap:any = {};
        const aggIndexes:number[] = aggColIndexes;

        for (const row of data) {
            const arrKeys:string[] = [];
            aggIndexes.map(item => {
                arrKeys.push(row[item]);
            })
            const key = arrKeys.join(',');
            const existingRow = resultMap.get(key);
            const existingExtend = extendMap[key];

            if (existingRow) {
                const calcColumns = (item:any, i:number) => {
                        const columnNames = column.map((item:any)=>item.name);
                        const indexes:Array<number> = item.fields.map((item:string) => columnNames.indexOf(item));
                        const operation = item.operation;
                        const type = item.type;

                        if(operation === 'sum') {
                            let result = 0;
                            indexes.map(index=> {
                                if(index !== -1 && isNumber(row[index])) {
                                    result += +row[index];
                                }
                            })
                            return changeCol(existingRow[i], result, 'sum', type);
                        }
                        if(operation === 'multi') {
                            let result = 1;
                            indexes.map(index=> {
                                if(index !== -1 && isNumber(existingRow[index])) {
                                    result *= +existingRow[index];
                                }
                            })
                            return getDefaultValue(result, type);
                        }
                        if(operation === 'dif') {
                            let result = 0;
                            indexes.map((key:number, index:number)=> {
                                if(key !== -1 && isNumber(row[key])) {
                                    if(index === 0) result = +row[key];
                                    else result -= +row[key];
                                }
                            })
                            return changeCol(existingRow[i], result, 'sum', type);
                        }
                        if(operation === 'div') {
                            let result:number = 1;
                            indexes.map((key, index)=> {
                                if(key !== -1 && isNumber(existingRow[key])) {
                                    if(index === 0) {
                                        result = +existingRow[key];
                                    } else {
                                        if(+existingRow[key] === 0) {
                                            result = 0;
                                        } else if(+existingRow[key] !== 0 && result)
                                            result /= +existingRow[key];
                                    }
                                }
                            })
                            return getDefaultValue(result, type);
                        }
                }
                column.map((item, i)=> {
                    if(!aggIndexes.includes(i)) {
                        if(item.agg_function === 'calc') {
                            row.splice(i, 0, 0);
                            existingRow[i] = calcColumns(item, i);
                        } else
                            existingRow[i] = changeCol(existingRow[i], row[i], item.agg_function, item.type);

                    }
                    existingExtend.count+=1;
                })

            } else {
                const value:any = [];
                const calcColumns = (item:any) => {
                        const columnNames = column.map((item:any)=>item.name);
                        const indexes:Array<number> = item.fields.map((item:string) => columnNames.indexOf(item));
                        const operation = item.operation;
                        const type = item.type;
                        if(operation === 'sum') {
                            let result = 0;
                            indexes.map(index=> {
                                if(index !== -1 && isNumber(row[index])) {
                                    result += +row[index];
                                }
                            })
                            value.push(getDefaultValue(result, type))
                        }
                        if(operation === 'multi') {
                            let result = 1;
                            indexes.map(index=> {
                                if(index !== -1 && isNumber(row[index])) {
                                    result *= +row[index];
                                }
                            })
                            value.push(getDefaultValue(result, type));
                        }
                        if(operation === 'dif') {
                            let result = 0;
                            indexes.map((key:number, index)=> {
                                if(index === 0)
                                    result = +row[key];
                                else result -= +row[key];
                            })
                            value.push(getDefaultValue(result, type));
                        }
                        if(operation === 'div') {
                            let result:number = 1;
                            indexes.map((key, index)=> {
                                if(key !== -1 && isNumber(row[key])) {
                                    if(index === 0) {
                                        result = +row[key];
                                        return;
                                    } else {
                                        if(+row[key] === 0) {
                                            result = 0;
                                        } else if(+row[key] !== 0 && result !== 0) result /= +row[key];
                                    }
                                }
                            })
                            value.push(getDefaultValue(result, type));
                        }
                }
                column.map((item, index)=> {
                    if((item.type == 'string' || item.agg_function==='skip') && !aggIndexes.includes(index)) value.push('8|}$$~');
                    else if(item.agg_function === 'calc') {
                        row.splice(index, 0, 0);
                        calcColumns(item);
                    }
                    else if(item.type === 'float' || item.type === 'percent' || item.type === 'currency') value.push(getDefaultValue(row[index], item.type));
                    else value.push(row[index]);
                })
                resultMap.set(key, value);
                extendMap[key] ={count:1};
            }
        }
        return [Array.from(resultMap.values()), Object.values(extendMap).map((item:any) => item.count)];
    } catch (e) {
        console.log('Error - ', e);
        return [[],[1]];
    }
}