import {FC, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../../../redux/hooks'

import {currentUser, currentUserLang, userId} from '../../../../../redux/slices/user'
import {
    errorValue,
    getIsLoading,
    placementsData,
    placementsLoaded,
    removeError, setPlacements
} from '../../../../../redux/slices/placements'
import {currentCampaign, setBreadcrumbs} from '../../../../../redux/slices/breadcrumbs'
import {setSidebar} from "../../../../../redux/slices/sidebar";
import {projectGeneral} from '../../../../../redux/slices/project'
import {ModalName, openModal} from '../../../../../redux/slices/modals';

import {
    deleteMultiplePlacement,
    deletePlacement,
    downloadCampaignPixelsCsv,
    downloadPlacementsData, getPlacementsGenerals
} from '../../../../../actions/placements'
import {downloadCurrentCampaignData} from '../../../../../actions/breadcrumbs'
import {getProjectGeneral} from "../../../../../actions/project";

import {Btn, SearchForm} from '../../../../../ui'
import {itemsFilterFunction, useShowError} from '../../../../../helpers'

import {HeaderTable, Loader, Options, Table} from '../../../../../components'

import Empty from "../../../../../components/Empty";

import {ReactComponent as ArrowDownIcon} from '../../../../../assets/icons/icon-arrow-down.svg'
import {ReactComponent as PlusIcon} from '../../../../../assets/icons/icon-plus.svg'

import {IPlacements} from './interfaces.d'
import cx from 'classnames'
import Pagination from "../../../../../components/ui/Pagination";
import {getCountPage} from "../../../../../components/Tables/TablePagination/functions";
import {trans} from "../../../../../_locales";
import {getValueByNestedKey, sendSdk} from "../../../../../utils";
import MultipleActions from "../../../../../components/MultipleActions";
import {deleteCampaign, downloadCampaignsTemplateXlsx} from "../../../../../actions/media";
import {DefaultObject} from "../../../../../interfaces/common.d";
import {getDashboardsFilterValues} from "../../../../../actions/dashboards";
import {clearInstructions, setInstructions} from "../../../../../redux/slices/instructions";
import {getPages} from "../../../../../components/SideMenu/Role";

const Placements: FC<IPlacements> = ({ noPlatforms, withMocks }) => {
  const dispatch = useAppDispatch();
  const reduxUser =  useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const { projectId, campaignId } = useParams();
  const reduxUserIdRef = useRef<number | null>(null);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const reduxCurrentCampaign = useAppSelector(currentCampaign);
  const isLoading = useAppSelector(getIsLoading);
  const navigate = useNavigate();
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('campaigns') || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('campaigns')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('campaigns')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('campaigns')  || reduxCurrentProject && reduxCurrentProject.owner_id === reduxUserId;

  if(permissions && !isView) {
    navigate(`/projects/${projectId}/campaigns/`)
  }

  useEffect(()=> {
    if(projectId && reduxUserId) {
      sendSdk('page_view', {'user': {'uid': reduxUserId},'event_params': {'project_id': projectId, 'user_id': reduxUserId, 'page': 'creative'}})
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(setBreadcrumbs([
        {
          href: '/projects/',
          title: 'Проект',
          subtitle: reduxCurrentProject?.name
        },
        {
          href: `/projects/${projectId}/campaigns`,
          title: trans('Campaign', language),
          subtitle: reduxCurrentCampaign?.name
        },
        {
          href: '#',
          title: trans('Placements', language),
        },
        {
            href: `/projects/${projectId}/campaigns`,
            title: trans('Back', language),
        }
      ]))
    dispatch(setSidebar(getPages({
        projectId: Number(projectId),
        access: permissions && permissions['can_view'],
        language,
        active: 'placements'
    })))
    dispatch(clearInstructions());
    dispatch(setInstructions({
        title: trans('Instruction', language),
        cb: ()=> {
            dispatch(openModal({
                name: ModalName.DOCS_CREATE_CAMPAIGN_FULL,
                data: {}
            }))
        }
    }))
  }, [dispatch, projectId, reduxCurrentCampaign, reduxCurrentProject]);

  useEffect(() => {
    if (reduxUserId && projectId && campaignId && reduxUserId !== reduxUserIdRef.current) {
        reduxUserIdRef.current = reduxUserId;
        dispatch(downloadPlacementsData({currentUserId: reduxUserId, projectId: +projectId, campaignId: +campaignId}));
        dispatch(getProjectGeneral({currentUserId: reduxUserId, projectId: +projectId}));
        dispatch(downloadCurrentCampaignData({campaignId: +campaignId}));
        dispatch(getDashboardsFilterValues({projectId: projectId ? +projectId : -1, name: 'geo'})).then((r) => {
            if (r.payload) {
                setDatasetGeo(r.payload)
            }
        })
        dispatch(getDashboardsFilterValues({projectId: projectId ? +projectId : -1, name: 'targeting'})).then((r) => {
            if (r.payload) {
                setDatasetTargeting(r.payload)
            }
        })
    }
  }, [reduxUserId, projectId, campaignId, dispatch]);

  useShowError(errorMessage as string, removeError);

  const reduxPlacementsData = useAppSelector(placementsData);
  const reduxCampaignsDataForCampaign = reduxPlacementsData?.[projectId as string]?.[campaignId as string];
  const reduxPlacementsLoaded =  useAppSelector(placementsLoaded);
  const tableData = (reduxCampaignsDataForCampaign || []);
  const [dataWrapper, setDataWrapper] = useState<Array<any>>([...tableData]);
  const [geoUnique, setGetUnique] = useState<any>({});
  const [targetingUnique, setTargetingUnique] = useState<any>({});
  const [datasetGeo, setDatasetGeo] = useState<DefaultObject<string>>({});
  const [datasetTargeting, setDatasetTargeting] = useState<DefaultObject<string>>({});

  useEffect(()=> {
    setDataWrapper([...tableData]);
    const geoList = {};
    const targetingList = {};
    tableData.map((item, index)=> {
        if(item.targeting) {
            (targetingList as any)[item.targeting] = item.targeting;
        } else {
            (targetingList as any)['undefined'] = trans('Undefined', language)
        }
        if(item.geo) {
            (geoList as any)[item.geo] = item.geo;
        } else {
            (geoList as any)['undefined'] = trans('Undefined', language)
        }
    })
    setGetUnique(geoList);
    setTargetingUnique(targetingList);
  },[reduxPlacementsData, reduxCampaignsDataForCampaign])

  const onFilterData = (dataWrapper:any) => dataWrapper.filter((item: any) => {
    return itemsFilterFunction({
      formData: filterFormData,
      statusField: item.status,
      dateField: item.created_at,
      nameField: item.name,
      searchValue: searchValue,
      formatField: item.format,
      geoField: item.geo ? item.geo : trans('Undefined', language),
      targetingField: item.targeting ? item.targeting : trans('Undefined', language),
      type: 'placements',
      geoList: geoUnique,
      targetingList: targetingUnique
    });
  });

  const [searchValue, setSearchValue] = useState<string>('');
  const [filterFormData, setFilterFormData] = useState<FormData>(new FormData);
  const [tableDataFiltered, setTableDataFiltered] = useState<any>(onFilterData(dataWrapper));

  useEffect(()=> {
      setTableDataFiltered(onFilterData(dataWrapper))
  }, [searchValue, dataWrapper, filterFormData])

  const downloadHandler = (mode: 'all' | 'active' | number[]) => {
    if (reduxUserId && projectId && campaignId) {
      dispatch(downloadCampaignPixelsCsv({
        mode: mode,
        userId: reduxUserId,
        projectId: +projectId,
        campaignId: +campaignId
      }))
      sendSdk('event', {
          'event_type': 'download',
          'event_category': 'creative',
          'event_name': typeof mode === 'string' ? mode : 'selected',
          'event_value': '10',
          'event_params': {
              'project_id': projectId,
              'user_id': reduxUserId,
              'campaign_id': campaignId
          }
      });
    }
  }
  const downloadTemplatesHandler = (mode: 'all' | 'active' | number[]) => {
    if (reduxUserId && projectId && campaignId) {
      dispatch(downloadCampaignsTemplateXlsx({
        mode: mode,
        userId: reduxUserId,
        projectId: +projectId,
        campaignId: +campaignId
      }))
    }
  }

  const getChosenIds = () => {
      if (Object.keys(placementsIsChecked).length && projectId && campaignId) {
          const currentCampaigns = reduxPlacementsData && reduxPlacementsData[projectId] && reduxPlacementsData[projectId][campaignId] ? reduxPlacementsData[projectId][campaignId] : [];
          const ids = currentCampaigns.map(item => item.id);
          const temp: any[] = [];
          Object.keys(placementsIsChecked).map((item) => {
              if (placementsIsChecked[item] && ids.includes(+item)) temp.push(item);
          })
          return temp;
      }
      return Array.from(document.querySelectorAll('input[name="row-choice"]:checked')).map((input: any) => input.value);
  };

  const [chosenLength, setChosenLength] = useState<number>(0);
  const onChoice = (value:any) => {
    setPlacementsIsChecked(value);
    const count = getChosenIds().length;
    setChosenLength(count);
  }
  const updatedChosen = () => {
    const list = getChosenIds();
    const temp:DefaultObject<boolean> = {};
    list.map((item)=> {
      temp[item] = true;
    })
    setPlacementsIsChecked(temp);
    setChosenLength(list.length);
  }

  useEffect(()=> {
    updatedChosen();
  }, [reduxPlacementsData])

  const onDeletePlacements = (userId:any, projectId:any, campaignId:number, ids:Array<number>) => {
    dispatch(openModal({
      name: ModalName.CONFIRM_DELETE,
      data: {
        text: trans('Confirm the deletion of the creatives', language),
        onConfirm: () => {
            deleteChoicesPlacement(userId, projectId, campaignId, ids);
            dispatch(downloadPlacementsData({projectId, campaignId}))
        }
      }
    }))
  }
  const deleteChoicesPlacement = (userId:number, projectId:number, campaignId:number, ids:Array<number> = []) => {
    removePlacement(userId, projectId, campaignId, ids);
    setChosenLength(0);
  }
  const removePlacement = (userId:any, projectId:any, campaignId:any, placementIds:Array<number> = []) => {
      sendSdk('event', {
          'event_type': 'delete',
          'event_category': 'creative',
          'event_name': 'action',
          'event_value': '10',
          'event_params': {
              'project_id': projectId,
              'user_id': userId,
              'campaign_id': campaignId,
              'placement_id': placementIds.join(',')
          }
      });
      return dispatch(deleteMultiplePlacement({
          projectId: projectId,
          campaignId: campaignId,
          placementIds: placementIds
      }))
  }

  const contentClassName = cx('page-content__content', {'empty': noPlatforms || tableData.length === 0});

  const titleName = ['','','name',`stats.impression`,'stats.clicks','stats.reach','stats.spent','stats.leads','stats.postview_cpa','created_at']
  const onChangeSortTableCustom = (event:any, index:number) => {
        const element = event.target.closest('th');
        const tempData = [...dataWrapper];

        if(element.classList.contains('ascending')) {
          const tempData = [...dataWrapper];
          setDataWrapper(tempData.sort(function (a, b) {
              const first = getValueByNestedKey(a, titleName[index]),
                  second = getValueByNestedKey(b, titleName[index]);
              if (first < second) {
                return 1;
              }
              if (first > second) {
                return -1;
              }
              return 0;
          }));
          element.className = 'descending';
          return;
        }
        if(element.classList.contains('descending')) {
          element.className = '';
          setDataWrapper([...tableData]);
          return;
        }
        setDataWrapper(tempData.sort(function (a, b) {
          const first = getValueByNestedKey(a, titleName[index]),
                second = getValueByNestedKey(b, titleName[index]);
          if (first > second) {
            return 1;
          }
          if (first < second) {
            return -1;
          }
          return 0;
        }));
        Array.from(element.parentNode.childNodes).map((item:any) => (
            item.className = ''
        ))
        element.className = 'ascending';
  }

  const [limit, setLimit] = useState(30);
  const per_page_max = getCountPage(tableDataFiltered.length, limit);
  const [currentPage, setCurrentPage] = useState(1);

  const [allChecked, setAllChecked] = useState(false);
  const [placementsIsChecked, setPlacementsIsChecked] = useState<any>({})

    const [headOptions, setHeadOptions] = useState<any>([]);
    useEffect(() => {
        if(permissions) {
            const temp: any = [];
            if(isView) {
                temp.push({
                    type: 'popup',
                    tooltip: trans('Download', language),
                    data: {
                        btn: {
                            icon: {
                                Component: ArrowDownIcon,
                                width: 16,
                                height: 18
                            },
                            isIconBtn: true,
                            size: 'lg'
                        },
                        isCenter: true,
                        dropdownList: {
                            items: [
                                {
                                    text: trans('Download selected', language),
                                    onClick: () => {
                                        downloadHandler(getChosenIds());
                                    }
                                },
                                {
                                    text: trans('Download active', language),
                                    onClick: () => {
                                        downloadHandler('active');
                                    }
                                },
                                {
                                    text: trans('Download all', language),
                                    onClick: () => {
                                        downloadHandler('all');
                                    }
                                },
                            ]
                        },
                    }
                });
                temp.push({
                    type: 'filter',
                    tooltip: trans('Filter', language),
                    data: {
                        applyCallback: (data: FormData) => {
                            setFilterFormData(data);
                        },
                        type: 'placements',
                        geoList: geoUnique,
                        targetingList: targetingUnique,
                        language,
                        projectId: Number(projectId),
                        campaignId: Number(campaignId)
                    }
                });
                if (isCreate) {
                    temp.push({
                        type: 'btn',
                        tooltip: trans('Create new placement', language),
                        data: {
                            icon: {
                                Component: PlusIcon,
                                width: 23,
                                height: 23,
                            },
                            onClick: () => {
                                dispatch(openModal({
                                    name: ModalName.NEW_PLATFORM,
                                    data: {
                                        userId: reduxUserId,
                                        projectId: projectId ? +projectId : -1,
                                        campaignId: campaignId ? +campaignId : -1,
                                        sourceId: (reduxCurrentCampaign ? reduxCurrentCampaign.source_id : -1)
                                    }
                                }))
                                sendSdk('event', {
                                    'event_type': 'open',
                                    'event_category': 'creative',
                                    'event_name': 'create',
                                    'event_value': '10',
                                    'event_params': {
                                        'project_id': projectId,
                                        'user_id': reduxUserId,
                                        'campaign_id': campaignId
                                    }
                                });
                            }
                        }
                    });
                }
            }
            setHeadOptions(temp);
        }
    }, [permissions]);
  return (
    // <Base headerData={headerData} sidebarData={sidebarData} border={true}>
      <>
      <div className={contentClassName}>
        {(noPlatforms || ( reduxPlacementsLoaded && tableData.length === 0)) && (
          <div className="create">
            <h2 className="title title--black">
                {trans('Placementies', language)}<br/>{trans('not found', language)}
            </h2>
            {isCreate && <Btn
              text={trans('Create', language)}
              icon={{
                Component: PlusIcon,
                width: 12,
                height: 12,
              }}
              color='light-blue'
              size='md'
              onClick={() => {
                  dispatch(openModal({
                      name: ModalName.NEW_PLATFORM,
                      data: {
                          userId: reduxUserId,
                          projectId: projectId ? +projectId : -1,
                          campaignId: campaignId ? +campaignId : -1,
                          sourceId: (reduxCurrentCampaign ? reduxCurrentCampaign.source_id : -1)
                      }
                  }))
                  sendSdk('event', {
                      'event_type': 'open',
                      'event_category': 'creative',
                      'event_name': 'create',
                      'event_value': '10',
                      'event_params': {
                          'project_id': projectId,
                          'user_id': reduxUserId,
                          'campaign_id': campaignId
                      }
                  });
              }}
              // disabled={role === 'view'}
            />}
          </div>
        )}
        {!noPlatforms && tableData.length > 0 && (
          <>
            <div className="page-content__header">
              <SearchForm
                value={searchValue}
                onChangeCb={(value) => {
                  setSearchValue(value);
                }}
              />

                <Options
                    items={headOptions}
                />


            </div>

            {
              tableDataFiltered.length > 0 ?
                  <>
                    <HeaderTable onChangeSort={onChangeSortTableCustom} allChecked={allChecked} onAllChecked={(value:boolean=true)=>{
                        setAllChecked(value);
                        const temp:any = {};
                        let count = 0;
                        tableDataFiltered.map((item:any)=> {
                            temp[item.id] = value;
                            if(value) count++;
                        })
                        setPlacementsIsChecked(temp);
                        setChosenLength(count);
                    }}/>
                    <Table type='placement' rowsData={tableDataFiltered.slice((currentPage-1)*limit, currentPage*limit)} allChecked={allChecked} onChoice={onChoice} chosen={placementsIsChecked}/>
                  </>
                   :
                    <div className="create create_in-block">
                      <div>
                        <Empty title={trans('Nothing found', language)} />
                      </div>
                      <Btn
                        text={trans('Create', language)}
                        icon={{
                          Component: PlusIcon,
                          width: 12,
                          height: 12,
                        }}
                        color='light-blue'
                        size='md'
                        onClick={() => {
                            dispatch(openModal({
                                name: ModalName.NEW_PLATFORM,
                                data: {
                                    userId: reduxUserId,
                                    projectId: projectId ? +projectId : -1,
                                    campaignId: campaignId ? +campaignId : -1,
                                    sourceId: (reduxCurrentCampaign ? reduxCurrentCampaign.source_id : -1)
                                }
                            }))
                            sendSdk('event', {
                                'event_type': 'open',
                                'event_category': 'creative',
                                'event_name': 'create',
                                'event_value': '10',
                                'event_params': {
                                    'project_id': projectId,
                                    'user_id': reduxUserId,
                                    'campaign_id': campaignId
                                }
                            });
                        }}
                        disabled={isView && !isCreate}
                      />
                    </div>
            }
          </>
        )}
        {
          tableDataFiltered.length > 0 && per_page_max > 1 &&
          <Pagination
                sortFront={true}
                onChangeLimitChannel={null}
                limitChannelCustom={limit}
                setLimitChannelCustom={setLimit}
                per_page_max={per_page_max}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                bigData={true}
          />
        }
        {
          chosenLength > 1 && <MultipleActions
              count={chosenLength}
              type={'placements'}
              onChange={()=>{
                  dispatch(getPlacementsGenerals({campaignId: campaignId ? +campaignId : -1, placementIds: getChosenIds()})).then((r)=> {
                      let initialValues:any = {};
                      if(r.payload) {
                          initialValues = r.payload;
                      }
                      dispatch(openModal({
                          name: ModalName.EDIT_MULTI_PLACEMENTS,
                          data: {
                              userId: reduxUserId,
                              projectId: projectId ? +projectId : -1,
                              ids: getChosenIds(),
                              campaignId: campaignId,
                              initialValues: initialValues,
                              geoList: datasetGeo,
                              targetingList: datasetTargeting,
                          }
                      }))
                  })
              }}
              onDownload={()=>downloadHandler(getChosenIds())}
              onDownloadTemplate={()=>downloadTemplatesHandler(getChosenIds())}
              onDelete={()=>{
                  onDeletePlacements(reduxUserId, projectId, campaignId ? +campaignId : -1, getChosenIds());
              }}
              onReset={()=> {
                const temp:any = {};
                tableDataFiltered.map((item:any)=> {
                  temp[item.id] = false;
                })
                setPlacementsIsChecked(temp)
                setChosenLength(0);
                setAllChecked(false);
              }}
              isEdit={isEdit}
              isDelete={isDelete}
          />
        }
      </div>
      {(isLoading) &&
        <Loader inBlock={true}/>}
    </>
  )
}

export default Placements;
