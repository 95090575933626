import InputSearch from "../../../ui/InputSearch";
import SelectOptions from "../../../ui/Select/Options";
import {FC, useRef, useState} from "react";
import {ITablePaginationHeadItem} from "../interfaces";
import {trans} from "../../../../_locales";
import {useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import filterIcon from '../../../../assets/icons/icon-filter-blue.svg'
import { ReactComponent as PinIcon} from '../../../../assets/icons/pin.svg'
import {useOnClickOutside} from "../../../../helpers";
import Select from "../../../ui/Select";
import Input from "../../../../ui/Input";
import Btn from "../../../../ui/Btn";
import {removeSpacesInString} from "../../../../utils";
import {onChangeGeneralSelect} from "../../../ui/Select/functions";
import {onStretchBlock} from "./functions";
import StringSortByValue from "./StringSortByValue";
import cx from "classnames";

const TablePaginationHeadItem:FC<ITablePaginationHeadItem> = ({
    className,
    title,
    index,
    name,
    fieldType,
    fieldTypes,
    activeFilter={},
    setActiveFilter,
    activeSearch={},
    setActiveSearch,
    activePin={},
    setActivePin,
    onFilter,
    valueSearch,
    activeSort={},
    activeIndex,
    onChangeSorting,
    data,
    dataWrapper,
    setDataWrapper,
    offFilter=false,
    setCur,
    itemCount,
    setItemCount,
    lastElementWithType
}) => {
    const language = useAppSelector(currentUserLang);
    const [flagSort, setFlagSort] = useState(false);
    const sortDataset = {
        'ascending': trans('Ascending', language),
        'descending': trans('Descending', language),
        'default': trans('Default', language),
    }
    const onChangeSort = (type:'ascending' | 'descending' | 'default'='default') => {
        setFlagSort(false);
        if(type === 'default') {
            onFilter(activeSearch, activeFilter);
        } else {
            onFilter(activeSearch, activeFilter, {[index]: type})
        }
    };
    const onChangeCurrentSearch = (value:string) => {
        const temp = JSON.parse(JSON.stringify(activeSearch));
        temp[index] = value;
        setActiveSearch(temp);
        onFilter(temp, activeFilter, activeSort);
    }

    const [flagSortByValue, setFlagSortByValue] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const handleMouseEnter = (e:any) => {
       const box = e.target.closest('.table-pagination__title');
       if(box) {
           const elem = box.querySelector('.table-pagination__title-text');
           if(elem && elem.clientHeight > 30) {
               setShowTooltip(true);
           }
       }
    }

    const handleMouseLeave = () => {
       setShowTooltip(false);
    }

    const NumberSortByValue = ({
        activeFlag=false,
        setActiveFlag,
    }:{activeFlag?:boolean,setActiveFlag?:any}) => {
        const selectRef = useRef<HTMLDivElement>(null);
        useOnClickOutside(() => {
            setActiveFlag(false)
        }, [selectRef]);

        const [value, setValue] = useState<string>(activeFilter[index] ? activeFilter[index].value : '');
        const [condition, setCondition] = useState<string>(activeFilter[index] ? activeFilter[index].condition : '');
        const datasetConditions = {
            more: trans('More', language),
            less: trans('Less', language),
            equal: trans('Equal', language)
        }

        const onSubmit = ()=> {
            const temp = JSON.parse(JSON.stringify(activeFilter));
            temp[index] = {
                condition: condition,
                value: removeSpacesInString(value)
            }
            setActiveFilter(temp);
            onFilter(activeSearch, temp, activeSort);
            setActiveFlag(false);
        }
        const onReset = ()=> {
            setCondition('');
            setValue('');
            const temp = JSON.parse(JSON.stringify(activeFilter));
            delete temp[index];
            setActiveFilter(temp);
            onFilter(activeSearch, temp, activeSort);
            setActiveFlag(false);
        }

        return(
            <div className={`number-sort-modal ${activeFlag ? 'active' : ''}`} ref={selectRef}>
                <div className="number-sort-modal__choice">
                    <Select
                        color='dark'
                        selectActive={(datasetConditions as any)[condition] ? (datasetConditions as any)[condition] : trans('Condition', language)}
                        dataset={datasetConditions}
                        onChangeActive={(res:any)=> onChangeGeneralSelect(res, setCondition)}
                    />
                    <Input
                        name={'value'}
                        type={'float'}
                        value={value}
                        color={'dark'}
                        placeholder={trans('Value', language)}
                        onChange={(e) => setValue(e.value)}
                        isDisabled={!(datasetConditions as any)[condition]}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        className={'number-sort-modal__submit'}
                        text={trans('Apply', language)}
                        color={'blue'}
                        onClick={onSubmit}
                        isfullWidth={true}
                        disabled={!(value !== '' && +(removeSpacesInString(value)) >= 0)}
                    />
                </div>
                <div className="number-sort-modal__btn">
                    <Btn
                        className={'number-sort-modal__reset'}
                        text={trans('Reset', language)}
                        color={'light-blue'}
                        onClick={onReset}
                        isfullWidth={true}
                    />
                </div>
            </div>
        )
    }

    const contentClassName = cx('table-pagination__th',
        className,
        fieldType === 'string' ? 'search' : '',
        fieldType === 'date' ? 'date' : '',
        lastElementWithType ? 'last' : '',
        activePin[index] ? 'sticky' : ''
    );
    const [isPin, setIsPin] = useState(false);

    const onPinColumn = () => {
        // setIsPin(!isPin);
    }

    return (
            data[0][index] !== '8|}$$~' && offFilter ?
            <th key={index} className={contentClassName}>
                <div className='table-pagination__cell'>
                    <div
                        className={`table-pagination__title ${index === 0 ? 'left':''}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className='table-pagination__title-text'>
                            {title ? title : name}
                        </div>
                        {(showTooltip && title && title.toString().length > 23) && <div className="alt right active">
                            <span>{title ? title : name}</span>
                        </div>}
                    </div>
                    <button
                        className={'table-pagination__stretch'}
                        onMouseDown={(e)=>{
                            const result = onStretchBlock(e);
                            setCur(result)
                        }}
                    ></button>
                </div>
            </th>
            : (data[0][index] !== '8|}$$~' && fieldType === 'string') ?
            <th key={index}
                className={contentClassName} style={activePin[index] && activePin[index].left?{left: `${activePin[index].left}px`}: {}}>
                <div className={`table-pagination__cell ${fieldType === 'string' ? 'search' : ''} ${index === 0 ? 'left':''}`}>
                    <div className={`table-pagination__title ${index === 0 ? 'left':''}`}>
                        <InputSearch
                            name={title ? title : name}
                            sortBackend={true}
                            valueSearch={activeSearch[index]}
                            onChange={onChangeCurrentSearch}
                            onChangeValueSearch={onChangeCurrentSearch}
                        />
                        <button className={`table-pagination__pin ${activePin[index] ? 'active': ''}`} onClick={()=>{
                            onPinColumn();
                            // const temp = activePin;
                            // if(temp[index]) {
                            //     delete temp[index];
                            //     return;
                            // }
                            // if(Object.keys(temp).length > 1) return;
                            // temp[index] = true;
                            setActivePin(index);
                        }}>
                            <PinIcon />
                        </button>
                        <button
                            className={`table-pagination__filter ${activeFilter[index] ? 'active':''}`}
                            onClick={() => setFlagSortByValue(true)}
                        >
                            <img src={filterIcon} alt=""/>
                        </button>
                        <StringSortByValue
                            activeFlag={flagSortByValue}
                            setActiveFlag={setFlagSortByValue}
                            language={language}
                            onReset={()=> {
                                const temp = activeFilter;
                                delete temp[index];
                                setActiveFilter(temp);
                                onFilter(activeSearch, temp, activeSort);
                                setFlagSortByValue(false);
                            }}
                            onSubmit={()=>{
                                const temp = JSON.parse(JSON.stringify(activeFilter));
                                temp[index] = {
                                    condition: 'no_contain',
                                    value: ''
                                }
                                setActiveFilter(temp);
                                onFilter(activeSearch, temp, activeSort);

                                setFlagSortByValue(false);
                            }}
                        />
                    </div>
                    <button className={'table-pagination__stretch'} onMouseDown={(e)=> {
                        const result = onStretchBlock(e);
                        setCur(result)
                    }}></button>
                </div>
            </th> : data[0][index] !== '8|}$$~' ?
            <th key={index}
                className={contentClassName} style={activePin[index] && activePin[index].left?{left: `${activePin[index].left}px`}: {}}>
                <div className={`table-pagination__cell ${fieldType === 'date' ? 'date' : ''}`}>
                    <div
                        className={`table-pagination__title ${index === 0 ? 'left':''}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className='table-pagination__title-text'>
                            {title ? title : name}
                        </div>
                        {showTooltip && title && title.toString().length > 23 && <div className="alt right active">
                            <span>{title ? title : name}</span>
                        </div>}
                        {fieldType !== 'date' && <button
                            className={`table-pagination__filter ${activeFilter[index] ? 'active':''}`}
                            onClick={() => setFlagSortByValue(true)}
                        >
                            <img src={filterIcon} alt=""/>
                        </button>}
                        <button
                            className={`table-pagination__filter ${activeSort && (index in activeSort) && activeSort[index] !== 'default' ? 'active':''}`}
                            onClick={() => setFlagSort(true)}
                        >
                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.93421 3.19043L4.29132 0.833315L6.64844 3.19043" stroke="#007BFF"
                                      strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.29155 0.833301L4.29155 9.0832" stroke="#007BFF" strokeWidth="1.2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M13.2329 7.55945L10.8758 9.91656L8.51868 7.55945" stroke="#007BFF"
                                      strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.8755 1.66667L10.8755 9.91658" stroke="#007BFF" strokeWidth="1.2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </button>
                        <SelectOptions
                            onChange={onChangeSort}
                            dataWrapper={sortDataset}
                            activeFlag={flagSort}
                            setFlagSort={setFlagSort}
                            activeKey={activeSort && (index in activeSort) && activeSort[index] ? activeSort[index] : 'default'}
                        />
                        <NumberSortByValue
                            activeFlag={flagSortByValue}
                            setActiveFlag={setFlagSortByValue}
                        />
                    </div>
                    <button className={'table-pagination__stretch'} onMouseDown={(e)=> {
                        const result = onStretchBlock(e);
                        setCur(result);
                    }}></button>
                </div>
            </th> : <></>
    )
}

export default TablePaginationHeadItem;